import { Component, Input, Output, OnInit, EventEmitter, ViewChild, ElementRef} from '@angular/core';
import { ProductService } from '../../api/product.service';
import { NotificationsService } from 'angular2-notifications';

export interface CustomTable {
  thead?: string[];
  tbody: Array<Array<any | CustomTableButton[]>>;
  tdWidthArray?: string[];
  titem?: Array<any>;
}

export interface CustomTableButton {
  label: string;
  callback: Function;
  class?: string;
}


@Component({
  selector: 'app-custom-table',
  templateUrl: './custom-table.component.html',
  styleUrls: ['./custom-table.component.css']
})

export class CustomTableComponent implements OnInit{
  @Input() table?: CustomTable;
  @Input() header?: string;
  @Input() addRouterPath?: Array<string>;
  @Input() addFunc?: Function;
  @Input() addByInput?: Function;
  @Input() showSearch: boolean = false;
  @Input() totalCount = 0;
  @Input() showRegion = '';
  @Input('count') initialCount = 10;
  @Input() page = 1;
  @Input() addInputPlaceholder = 'Enter data...';
  @Input() showDynamics = false;
  @Input() publishFeature = false;
  @Input() importAllFeature = false;
  @Input() exportFeature = false;
  @Output() search = new EventEmitter();
  @Output() pageChange = new EventEmitter();
  @Output() publishStateEmitter = new EventEmitter();
  @Output() importAllEmitter = new EventEmitter<any>();
  @Output() exportAllEmitter = new EventEmitter();

  @ViewChild('importProductsFile', { static: true }) importProductsFile: ElementRef = new ElementRef(null);

  tdWidth: string[] = [];

  microsoftDynamicsLoading = false;
  publishState = true;

  constructor(private productService: ProductService, private _service: NotificationsService) {}

  ngOnInit(): void {
  }

  handleSearch($event: any, refToSearch: any) {
    $event.preventDefault();
    this.search.emit(refToSearch);
  }

  handleChangePublished(nextPublishState: boolean) {
    this.publishState = nextPublishState;
    this.publishStateEmitter.emit(this.publishState);
  }

  pageChanged() {
    this.pageChange.emit(this.page);
  }

  isArray(object: any): boolean {
    return Array.isArray(object);
  }

  isObject(object: any): boolean {
    return typeof object === 'object' && object !== null;
  }

  handleLoad(isUpdate = false) {
    if(isUpdate) {
      confirm('Are you sure want to update product from Microsoft Dynamics? This will result in rewriting some of product fields!') && this.productService.updateProducts().subscribe(data => {
        this._service.success('Success', 'Products is updated!');
      }, err => this._service.error('Error', 'Something went wrong!'));
    } else {
      this.microsoftDynamicsLoading = true;
      let result = confirm('Are you sure want to load ONLY published products from Microsoft Dynamics? If product is already in the cms it will be skipped!');
      this.productService.loadProducts(result).subscribe(data => {
        this._service.success('Success', 'Products is added!');
        this.microsoftDynamicsLoading = true;
      }, err => { this.microsoftDynamicsLoading = false; this._service.error('Error', 'Something went wrong!') });
    }
  }

  uploadFile(event: any) {
    const file = event.target.files[0];
    if (file) {
      this.importAllEmitter.emit(file);
      this.importProductsFile.nativeElement.value = '';
    }
  }
}

import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NotificationsService } from 'angular2-notifications';
import { AnalyticsUserService } from 'src/app/api/analytics-user.service';
import { CustomTable } from 'src/app/entities/custom-table/custom-table.component';
import { DeleteModalComponent } from 'src/app/entities/delete-modal/delete-modal.component';
import { AnalyticsUser } from 'src/app/models/analytics-user.model';
import { PaginationList } from 'src/app/models/pagination-list.model';

@Component({
  selector: 'app-analytics-users',
  templateUrl: './analytics-users.component.html',
  styleUrls: ['./analytics-users.component.css'],
})
export class AnalyticsUsersComponent implements OnInit {
  appTable?: CustomTable;
  totalCount = 0;
  page = 1;
  count = 40;
  search = '';

  constructor(
    private analyticsUserService: AnalyticsUserService,
    private _service: NotificationsService,
    public router: Router,
    private modalService: NgbModal
  ) {}

  ngOnInit(): void {
    this.refreshUsers();
  }

  refreshUsers() {
    this.analyticsUserService
      .getUsers(this.search, (this.page - 1) * this.count, this.count)
      .subscribe((usersList: PaginationList<AnalyticsUser>) => {
        this.totalCount = usersList.totalCount;
        this.appTable = {
          thead: ['Email', 'Id', 'Actions'],
          tbody: usersList.data.map((analyticsUser: AnalyticsUser) => {
            return [
              analyticsUser.email,
              analyticsUser.id,
              [
                {
                  label: 'Delete',
                  class: 'btn btn-sm btn-danger m-r-md',
                  callback: () => {
                    this.handleDelete(analyticsUser);
                  },
                },
                {
                  label: 'Edit',
                  class: 'btn btn-sm btn-primary',
                  callback: () => {
                    this.handleEdit(analyticsUser);
                  },
                },
              ],
            ];
          }),
        };
      });
  }

  handleAdd(email: string) {
    const body = {
      email,
    };
    this.analyticsUserService
      .addUser(body)
      .subscribe(() =>
        this._service.success('Success!', `${body.email} is added!`)
      );
  }

  handleEdit(analyticsUser: AnalyticsUser) {
    this.router.navigate(['', 'analytics-users', 'edit', analyticsUser.id]);
  }

  handleDelete(analyticsUser: AnalyticsUser) {
    const modalRef = this.modalService.open(DeleteModalComponent);
    modalRef.componentInstance.name = analyticsUser.email;
    modalRef.closed.subscribe((r) => {
      if (r === 'OK')
        analyticsUser.id &&
          this.analyticsUserService
            .deleteUser(analyticsUser.id)
            .subscribe(() => {
              this._service.success(
                'Success!',
                `${analyticsUser.email} is deleted!`
              );
              this.refreshUsers();
            });
    });
  }

  pageChange(event: number) {
    this.page = event;
    this.refreshUsers();
  }

  handleSearch(event: any) {
    this.search = event.value;
    this.page = 1;
    this.refreshUsers();
  }
}

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { User } from '../models/user.model';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  user: BehaviorSubject<User> = new BehaviorSubject(new User());

  constructor(private http: HttpClient) { }

  getUser() {
    return this.http.get<User>(`${environment.apiUrl}/api/User/GetUserInfo`);
  }
}

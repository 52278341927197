import { Component, OnInit } from '@angular/core';
import { Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

import { NotificationsService } from 'angular2-notifications';
import { AnalyticsUserService } from 'src/app/api/analytics-user.service';

import { AnalyticsUser } from 'src/app/models/analytics-user.model';
import { FormSettings } from 'src/app/models/formSettings.model';

@Component({
  selector: 'app-analytics-users-edit',
  templateUrl: './analytics-users-edit.component.html',
  styleUrls: ['./analytics-users-edit.component.css'],
})
export class AnalyticsUsersEditComponent implements OnInit {
  editMode = '';
  formSettings: FormSettings[] = [];
  analyticsUser = new AnalyticsUser();

  constructor(
    private activatedRoute: ActivatedRoute,
    private analyticsUserService: AnalyticsUserService,
    private _service: NotificationsService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.editMode = this.activatedRoute.snapshot.params['id'];
    if (this.editMode) {
      this.analyticsUserService
        .getUser(this.editMode)
        .subscribe((analyticsUser) => {
          this.analyticsUser = analyticsUser;
          this.setForm(this.analyticsUser);
        });
    } else {
      this.setForm();
    }
  }

  setForm(analyticsUser?: AnalyticsUser) {
    this.formSettings = [
      {
        type: 'input',
        title: 'Email',
        value: analyticsUser ? analyticsUser.email : '',
        placeholder: 'User Email...',
        controlName: 'email',
        validators: [Validators.required, Validators.email],
      },
    ];
  }

  handleSave(analyticsUser: AnalyticsUser) {
    const analyticsUserBody: AnalyticsUser = {
      id: this.analyticsUser.id,
      email: analyticsUser.email,
    };
    this.editMode
      ? this.analyticsUserService.editUser(analyticsUserBody).subscribe(
          () => {
            this._service.success('Success!', 'User is edited!');
            this.router.navigate(['', 'analytics-users']);
          },
          (e) => this._service.error('Error!', e.error)
        )
      : this.analyticsUserService.addUser(analyticsUserBody).subscribe(
          () => {
            this._service.success('Success!', 'User is created!');
            this.router.navigate(['', 'analytics-users']);
          },
          (e) => this._service.error('Error!', e.error)
        );
  }
}

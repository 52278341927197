<app-header></app-header>
<div class="app-wrapper" *ngIf="currUser?.userName">
    <div class="left-menu">
        <div class="menu-item" *ngFor="let item of pages" [routerLink]="item.url" (click)="activePage = item.id" [ngClass]="{'active': activePage == item.id}">
            {{item.name}}
        </div>
    </div>
    <div class="right-body">
        <router-outlet></router-outlet>
    </div>
</div>
<simple-notifications [options]="options"></simple-notifications>
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Validators } from '@angular/forms';
import { FormSettings } from '../../../models/formSettings.model';
import { FileCategory } from '../../../models/category.model';
import { Language } from '../../../models/cbo.model';
import { CategoryService } from '../../../api/category.service';
import { CBOService } from '../../../api/cbo.service';
import { NotificationsService } from 'angular2-notifications';

@Component({
  selector: 'app-file-categories-edit',
  templateUrl: './file-categories-edit.component.html',
  styleUrls: ['./file-categories-edit.component.css']
})
export class FileCategoriesEditComponent implements OnInit {

  editMode = '';
  formSettings: FormSettings[] = [];
  category = new FileCategory();
  allCategories: FileCategory[] = [];
  languages: Language[] = [];

  constructor(private activatedRoute: ActivatedRoute, private categoryService: CategoryService, private _service: NotificationsService, private router: Router, private cboService: CBOService) { }

  ngOnInit(): void {
    this.editMode = this.activatedRoute.snapshot.params['id'];
    if(this.editMode) {
      this.categoryService.getFileCategory(this.editMode).subscribe(cat => {
        this.category = cat;
        this.setForm(this.category);
      })
    } else {
      this.setForm();
    }

  }

  setForm(category?: FileCategory) {
    this.categoryService.getFileCategories().subscribe(categories => {
        this.allCategories = categories.data;
        this.cboService.getLanguages().subscribe(languages => {
          this.languages = languages;
          this.formSettings = [
            {type: 'input', title: 'Name', value: category ? category.name : '', placeholder: 'Category Name...', controlName: 'name', validators: [Validators.required]},
            {type: 'select', title: 'Parent Category', value: category ? category.parentId : '', valueItems: this.allCategories, controlName: 'parentId'},
            {type: 'select', title: 'Language', value: category ? category.cboLanguageId : '', valueItems: this.languages, controlName: 'cboLanguageId'},
          ];
        })
      })
  }

  handleSave(event: FileCategory) {
    let cat: FileCategory = {
      id: this.category.id,
      name: event.name,
      parentId: event.parentId,
      cboLanguageId: event.cboLanguageId
    }
    this.editMode ? 
    this.categoryService.editFileCategory(cat).subscribe(d => { this._service.success('Success!', 'File Category edited!'); this.router.navigate(['', 'file-categories']) }, err => { this._service.error('Error!', 'Something went wrong!') }) :
    this.categoryService.addFileCategory(cat).subscribe(d => { this._service.success('Success!', 'File Category created!'); this.router.navigate(['', 'file-categories']) }, err => { this._service.error('Error!', 'Something went wrong!') })
  }

}

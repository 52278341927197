import { Component, OnInit } from '@angular/core';
import { Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NotificationsService } from 'angular2-notifications';
import { forkJoin } from 'rxjs';
import { CertificationService } from 'src/app/api/certification.service';
import { HelperService } from 'src/app/helpers/helper.service';
import { Certification } from 'src/app/models/certification.model';
import { FormSettings } from 'src/app/models/formSettings.model';
import { Photo } from 'src/app/models/photo.model';

@Component({
  selector: 'app-certifications-edit',
  templateUrl: './certifications-edit.component.html',
  styleUrls: ['./certifications-edit.component.css']
})
export class CertificationsEditComponent implements OnInit {

  editMode = '';
  formSettings: FormSettings[] = [];
  photos: Photo[] = [];
  certification = new Certification();

  constructor(
    private activatedRoute: ActivatedRoute,
    private certificationService: CertificationService,
    private _service: NotificationsService,
    private hs: HelperService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.editMode = this.activatedRoute.snapshot.params['id'];
    if (this.editMode) {
      forkJoin({
        certification: this.certificationService.getCertification(this.editMode),
        certificationPhotos: this.certificationService.getCertificationPhotos(this.editMode),
      }).subscribe((join: {
        certification: Certification,
        certificationPhotos: {
          data: Photo[],
          totalCount: number
        }
      }) => {
        this.certification = join.certification;
        this.photos = join.certificationPhotos.data;
        this.setEditForm(this.certification);
      })
    } else {
      this.setAddForm();
    }
  }

  setAddForm(certification?: Certification) {
    this.formSettings = [
      {
        type: 'input',
        title: 'Name',
        value: certification ? certification.name : '',
        placeholder: 'Certification Name...',
        controlName: 'name',
        validators: [Validators.required]
      },
    ];
  }

  setEditForm(certification?: Certification) {
    this.formSettings = [
      {
        type: 'photos',
        title: '',
        value: this.photos,
        controlName: 'photo',
        validators: [Validators.required, Validators.maxLength(1)]
      },
      {
        type: 'input',
        title: 'Name',
        value: certification ? certification.name : '',
        placeholder: 'Certification Name...',
        controlName: 'name',
        validators: [Validators.required]
      },
    ];
  }

  handleSave(event: Certification) {
    let certification: Certification = {
      id: this.certification.id,
      name: event.name,
    }
    this.editMode ?
      this.certificationService.editCertification(certification).subscribe(certification => {
        this._service.success('Success!', 'Substrate Type edited!');
        this.handleSaveEntities(event, certification);
        this.router.navigate(['', 'certifications'])
      },
        err => {
          this._service.error('Error!', 'Something went wrong!')
        }) :
      this.certificationService.addCertification(certification).subscribe(certification => {
        this._service.success('Success!', 'Substrate Type created!');
        this.router.navigate(['', 'certifications'])
      },
        err => {
          this._service.error('Error!', 'Something went wrong!')
        })
  }

  handleSaveEntities(event: any, certification: Certification) {
    const certificationToCheck = new Certification(
      certification.name,
      certification.id,
      certification.entityId
    ); // needs creation with cunstructor to correctly check instanceof in handleChangeEntity
    //photos
    this.hs.handleChangeEntity(
      event.photo as Photo[],
      this.photos,
      certificationToCheck,
      (photo: any) => this.certificationService.editPhoto(photo),
      (photoId: any) => this.certificationService.deletePhoto(photoId)
    );
  }
}

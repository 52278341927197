import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, AbstractControl } from '@angular/forms';
import { FormSettings } from '../../../models/formSettings.model';
import { HelperService } from '../../../helpers/helper.service';

@Component({
  selector: 'app-form-select',
  templateUrl: './form-select.component.html',
  styleUrls: ['./form-select.component.css']
})
export class FormSelectComponent implements OnInit {

  @Input('settings') fs: FormSettings = new FormSettings();
  @Input('parentForm') pForm: AbstractControl = new FormGroup({});
  @Input() multi: boolean = false;
  control?: AbstractControl;

  constructor(public hs: HelperService) { }

  ngOnInit(): void {
    this.control = this.pForm.get(this.fs.controlName) || undefined;
  }

  getFormGroup() {
    return this.pForm as FormGroup;
  }

}

import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CustomTable } from '../../entities/custom-table/custom-table.component';
import { CategoryService } from '../../api/category.service';
import { FileCategory } from 'src/app/models/category.model';
import { NotificationsService } from 'angular2-notifications';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DeleteModalComponent } from '../../entities/delete-modal/delete-modal.component';


@Component({
  selector: 'app-file-categories',
  templateUrl: './file-categories.component.html',
  styleUrls: ['./file-categories.component.css']
})
export class FileCategoriesComponent implements OnInit {

  appTable?: CustomTable
  totalCount = 0;
  page = 1;
  count = 40;
  search = '';

  constructor(private categoryService: CategoryService, public router: Router, private _service: NotificationsService, private modalService: NgbModal) { }

  ngOnInit(): void {
    this.refreshCats();
  }

  refreshCats() {
    this.categoryService.getFileCategories(this.search, (this.page - 1) * this.count, this.count).subscribe(cats => {
      this.totalCount = cats.totalCount;
      this.appTable = {
        thead: ['Name', 'Id', 'Parent Id', 'Actions'],
        tbody: cats.data.map(i => {
          return [i.name, i.id, i.parentId, [{ label: 'Delete', class: 'btn btn-sm btn-danger m-r-md', callback: () => { this.handleDelete(i); } }, { label: 'Edit', class: 'btn btn-sm btn-primary', callback: () => { this.handleEdit(i); } }]];
        })
      };
    })
  }

  handleDelete(cat: FileCategory) {
    let modalRef = this.modalService.open(DeleteModalComponent);
    modalRef.componentInstance.name = cat.name;
    modalRef.closed.subscribe(r => {
      if (r === 'OK') cat.id && this.categoryService.deleteFileCategory(cat.id).subscribe(d => {
        this._service.success('Success!', `${cat.name} file category is deleted!`);
        this.refreshCats();
      })
    })
  }

  handleEdit(cat: FileCategory) {
    this.router.navigate(['', 'file-categories', 'edit', cat.id]);
  }

  pageChange(event: number) {
    this.page = event;
    this.refreshCats();
  }

  handleSearch(event: any) {
    this.search = event.value;
    this.page = 1;
    this.refreshCats();
  }
}

import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NotificationsService } from 'angular2-notifications';
import { ComplaintService } from 'src/app/api/complaint.service';
import { CustomTable } from 'src/app/entities/custom-table/custom-table.component';
import { DeleteModalComponent } from 'src/app/entities/delete-modal/delete-modal.component';
import { Complaint } from 'src/app/models/complaint.model';

@Component({
  selector: 'app-complaints',
  templateUrl: './complaints.component.html',
  styleUrls: ['./complaints.component.css']
})
export class ComplaintsComponent implements OnInit {

  appTable?: CustomTable
  totalCount = 0;
  page = 1;
  count = 100;
  search = '';
  complaints: Complaint[] = [];
  
  constructor(
    private complaintService: ComplaintService,
    public router: Router,
    private _service: NotificationsService,
    private modalService: NgbModal
  ) { }

  ngOnInit(): void {
    this.refreshComplaints();
  }

  handleEdit(complaint: Complaint) {
    this.router.navigate(['', 'complaints', 'edit', complaint.id]);
  }

  handleDelete(complaint: Complaint) {
    let modalRef = this.modalService.open(DeleteModalComponent);
    modalRef.componentInstance.name = complaint.name;
    modalRef.closed.subscribe(r => {
      if(r === 'OK') complaint.id && this.complaintService.deleteComplaint(complaint.id).subscribe(d => {
        this._service.success('Success!', `${complaint.name} complaint is deleted!`);
        this.refreshComplaints();
      })
    })
  }

  pageChange(event: number) {
    this.page = event;
    this.refreshComplaints();
  }

  handleSearch(event: any) {
    this.search = event.value;
    this.page = 1;
    this.refreshComplaints();
  }

  refreshComplaints() {
    this.complaintService.getComplaints(this.search, (this.page - 1) * this.count, this.count).subscribe(cats => {
      this.complaints = [];
      this.totalCount = cats.totalCount;
      
      this.formatComplaintsTree(cats.data.filter((complaint) => !cats.data.find((c) => c.id === complaint.parentId)), cats.data);

      this.appTable = {
        thead: ['Name', 'Actions'],
        tbody:  this.complaints.map(i => {
          return [{type: 'styledValue', value: i.name, style: {'padding-left.px': (i.level ?? 0) * 40}}, [{label: 'Delete', class: 'btn btn-sm btn-danger m-r-md', callback: () => { this.handleDelete(i); }}, {label: 'Edit', class: 'btn btn-sm btn-primary', callback: () => { this.handleEdit(i); }}]];
        })
      };
      
    })
  }

  formatComplaintsTree(branchcomplaints: Complaint[], allcomplaints: Complaint[], level: number = 0): void {    
    branchcomplaints.forEach((parentcomplaint) => {
      let subcomplaints = allcomplaints.filter((complaint) => complaint.parentId === parentcomplaint.id);
      parentcomplaint.level = level;
      this.complaints.push(parentcomplaint);
      if (subcomplaints) {
        this.formatComplaintsTree(subcomplaints, allcomplaints, level + 1);
      }
    });
  }
}

<form [formGroup]="mainForm" *ngIf="mainForm" (submit)="handleSubmit($event)">
    <ng-template ngFor let-item [ngForOf]="formSettings">
        <div class="form-item">
            <app-edit-images *ngIf="item?.type == 'photos'" [settings]="item" [parentForm]="mainForm"></app-edit-images>
            <app-form-input *ngIf="item?.type == 'input'" [settings]="item" [parentForm]="mainForm"></app-form-input>
            <app-form-text *ngIf="item?.type == 'textarea'" [settings]="item" [parentForm]="mainForm"></app-form-text>
            <app-form-select *ngIf="item?.type == 'select'" [settings]="item" [parentForm]="mainForm"></app-form-select>
            <app-form-select *ngIf="item?.type == 'multiselect'" [settings]="item" [parentForm]="mainForm" [multi]="true"></app-form-select>
            <app-form-files *ngIf="item?.type == 'catFiles'" [settings]="item" [parentForm]="mainForm"></app-form-files>
            <app-form-keystats *ngIf="item?.type == 'keystats'" [settings]="item" [parentForm]="mainForm"></app-form-keystats>
            <app-form-check *ngIf="item?.type == 'checkbox'" [settings]="item" [parentForm]="mainForm"></app-form-check>
            <app-form-tree-select *ngIf="item?.type == 'selectTree'" [settings]="item" [parentForm]="mainForm"></app-form-tree-select>
            <app-form-styled-textarea *ngIf="item?.type == 'styledTextarea'" [settings]="item" [parentForm]="mainForm"></app-form-styled-textarea>
            <div *ngIf="item?.type == 'info' && item?.value" class="m-t-lg m-b-md">
                <h4 *ngIf="item?.title">{{item.title}}</h4>
                <div>
                    {{item?.value}}
                </div>
            </div>
        </div>
    </ng-template>
    <div class="bottom-form" *ngIf="!hideSave">
        <button type="submit" class="btn btn-sm btn-primary">Save</button>
    </div>
</form>
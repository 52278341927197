<div class="form-files-wrapper" [formGroup]="pForm">
    <h4 *ngIf="fs?.title" class="m-t-lg m-b-md">{{fs?.title}}</h4>
    <div class="form-files-block">
        <table class="table table-striped">
            <thead>
                <tr>
                    <th scope="col">Name</th>
                    <th scope="col">Description</th>
                    <th scope="col">Actions</th>
                </tr>
            </thead>
            <tbody>
                <ng-container formArrayName="keystats">
                    <ng-container *ngFor="let item of control?.controls; let i = index">
                        <tr>
                            <td>
                                <app-form-input [settings]="getSettings('name')" [parentForm]="item"></app-form-input>
                            </td>
                            <td>
                                <app-form-input [settings]="getSettings('description')" [parentForm]="item"></app-form-input>
                            </td>
                            <td>
                                <button type="button" class="btn btn-sm btn-danger" (click)="handleDelete(i)">Delete</button>
                            </td>
                        </tr>
                    </ng-container>
                </ng-container>

            </tbody>
        </table>
    </div>
    <div>
        <button class="btn btn-sm btn-secondary" type="button" (click)="handleAddKeystat()">Add Technical Data</button>
    </div>
</div>
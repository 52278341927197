import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { Certification } from '../models/certification.model';
import { Photo } from '../models/photo.model';

@Injectable({
  providedIn: 'root'
})
export class CertificationService {

  constructor(private http: HttpClient) { }

  getCertification(id: string) {
    return this.http.get<Certification>(`${environment.apiUrl}/api/Certification/AdminGet?id=${id}`);
  }

  getCertifications(q = '', skip = 0, take = 40) {
    return this.http.get<{data: Certification[], totalCount: number}>(`${environment.apiUrl}/api/Certification/AdminList?skip=${skip}&take=${take}&query=${q}`);
  }

  addCertification(body: Certification) {
    return this.http.post<Certification>(`${environment.apiUrl}/api/Certification/Add`, body);
  }

  editCertification(body: Certification) {
    return this.http.put<Certification>(`${environment.apiUrl}/api/Certification/Edit`, body);
  }

  deleteCertification(id: string) {
    return this.http.delete<Certification>(`${environment.apiUrl}/api/Certification/Delete?id=${id}`);
  }

  getCertificationPhotos(certificationId: string) {
    return this.http.get<{data: Photo[], totalCount: number}>(`${environment.apiUrl}/api/Photo/AdminList?certificationId=${certificationId}`);
  }

  editPhoto(body: Photo) {
    return this.http.put<Photo>(`${environment.apiUrl}/api/Photo/Edit`, body);
  }

  deletePhoto(id: string) {
    return this.http.delete<Photo>(`${environment.apiUrl}/api/Photo/Delete?id=${id}`);
  }
}

<div #content>
    <div class="modal-header">
        <h4 class="modal-title" id="modal-title">Entity Deletion</h4>
        <button type="button" class="btn-close" aria-describedby="modal-title" (click)="modal.dismiss('Close')"></button>
    </div>
    <div class="modal-body">
        <p><strong>Are you sure you want to delete <span class="text-primary">{{name}}</span>?</strong></p>
        <p>All information associated to this entity will be permanently deleted.
            <span class="text-danger">This operation can not be undone.</span>
        </p>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-outline-primary" (click)="modal.dismiss('Cancel')">Cancel</button>
        <button type="button" class="btn btn-danger" (click)="modal.close('OK')">Ok</button>
    </div>
</div>
<div class="row productListContainer">
    <div class="col-md-12">
        <div class="row">
            <div class="col-md-6 prd-header">
                <h3 class="pull-left">Search settings</h3>
            </div>
            <div class="form-block col-md-6">
                <button class="btn btn-primary btn-sm m-r-md"
                    (click)="handleRefreshSearchCache()">
                    Resfresh Search Cache
                </button>
            </div>
        </div>
    </div>
</div>
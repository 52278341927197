export class AppType {
    name: string = '';
    id?: string;
    entityId?: string = '';
}

export class AppTypeProduct {
    applicationTypeId: string = '';
    productId: string = '';
    id?: string;
}
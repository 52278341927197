export class Product {
    constructor(
        name: string = '',
        id: string = '',
        description: string = '',
        vanityUrl: string = '',
        entityId: string = '',
        microsoftDynamicsId: string = '',
        features?: string,
        pageUrl?: string,
        published: boolean = true,
        externalId?: string
    ) {
        this.name = name;
        this.id = id;
        this.description = description;
        this.vanityUrl = vanityUrl;
        this.entityId = entityId;
        this.microsoftDynamicsId = microsoftDynamicsId;
        this.features = features;
        this.pageUrl = pageUrl;
        this.published = published;
        this.entityId = externalId;
    }

    id?: string;
    name: string;
    description?: string;
    vanityUrl?: string;
    entityId?: string;
    published?: boolean;
    microsoftDynamicsId?: string;
    features?: string;
    pageUrl?: string;
    externalId?: string;
}

export class ProductCategory {
    productCategoryId: string = '';
    productId: string = '';
    id?: string;
}

export class ProductComplaint {
    complaintId: string = '';
    productId: string = '';
    id?: string;
}
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Validators } from '@angular/forms';
import { FormSettings } from '../../../models/formSettings.model';
import { SubmittalPackage, SubmittalProduct } from '../../../models/submittal.model';
import { Product, ProductCategory } from '../../../models/product.model';
import { Category, FileCategory } from '../../../models/category.model';
import { Photo } from '../../../models/photo.model';
import { ProductFile } from '../../../models/file.model';
import { AppType, AppTypeProduct } from '../../../models/appType.model';
import { SubmittalService } from '../../../api/submittal.service';
import { ProductService } from '../../../api/product.service';
import { CategoryService } from '../../../api/category.service';
import { ApplicationService } from '../../../api/application.service';
import { HelperService } from '../../../helpers/helper.service';
import { NotificationsService } from 'angular2-notifications';
import { forkJoin } from 'rxjs';

import * as _ from 'lodash';

@Component({
  selector: 'app-submittal-package-edit',
  templateUrl: './submittal-package-edit.component.html',
  styleUrls: ['./submittal-package-edit.component.css']
})
export class SubmittalPackageEditComponent implements OnInit {

  editMode = '';
  formSettings: FormSettings[] = [];
  fileSettings: FormSettings[] = [];
  subPackage = new SubmittalPackage();
  products: Product[] = [];
  submittalProducts: Product[] = [];

  submittalCategories: Category[] = [];
  categories: Category[] = [];
  photos: Photo[] = [];

  files: ProductFile[] = [];
  fileCategories: FileCategory[] = [];

  selectedAppType: AppType[] = [];
  appTypes: AppType[] = [];

  constructor(
    private activatedRoute: ActivatedRoute,
    private subService: SubmittalService,
    private _service: NotificationsService,
    private router: Router,
    private productService: ProductService,
    private categoryService: CategoryService,
    private appTypeService: ApplicationService,
    private hs: HelperService
  ) { }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe(params => {
      this.editMode = this.activatedRoute.snapshot.params['id'];
      if (this.editMode) {
        forkJoin(
          this.subService.getSubmittalProduct(this.editMode),
          this.subService.getSubmittalCategory(this.editMode),
          this.categoryService.getCategories('', 0, 2000),
          this.productService.getProducts('', 0, 2000, true),
          this.subService.getPhotos(this.editMode),
          this.subService.getSubmittal(this.editMode),
          this.subService.getProductFiles(this.editMode),
          this.categoryService.getFileCategories('', 0, 2000),
          this.subService.getAppTypes(this.editMode),
          this.appTypeService.getAppTypes('', 0, 2000)
        ).subscribe((data) => {
          let products = data[0];
          let prodCat = data[1];

          this.categories = data[2].data;
          this.products = data[3].data;
          this.photos = data[4].data;
          this.subPackage = data[5];
          this.files = data[6].data;
          this.fileCategories = data[7].data;

          let appTypesSelected = data[8];
          this.appTypes = data[9].data;

          this.submittalCategories = [];
          this.submittalProducts = [];

          appTypesSelected.forEach((ap: AppTypeProduct) => {
            let exist = this.appTypes.find(c => c.id === ap.applicationTypeId);
            exist && this.selectedAppType.push({ ...exist, entityId: ap.id });
          })

          prodCat.forEach((prodCatItem: ProductCategory) => {
            let exist = this.categories.find(c => c.id === prodCatItem.productCategoryId);
            exist && this.submittalCategories.push({ ...exist, entityId: prodCatItem.id });
          })

          products.forEach((subProdItem: SubmittalProduct) => {
            let exist = this.products.find(c => c.id === subProdItem.productId);
            exist && this.submittalProducts.push({ ...exist, entityId: subProdItem.id });
          })

          this.setEditForm(this.subPackage);
        })
      } else {
        this.setAddForm();
      }
    })
  }



  setEditForm(subPackage?: SubmittalPackage) {
    this.formSettings = [
      { type: 'photos', title: '', value: this.photos, controlName: 'photo', validators: [Validators.required, Validators.maxLength(1)] },
      { type: 'input', title: 'Name', value: subPackage ? subPackage.name : '', placeholder: 'Submittal Name...', controlName: 'name', validators: [Validators.required] },
      { type: 'textarea', title: 'Description', value: subPackage ? subPackage.description : '', placeholder: 'Submittal Name...', controlName: 'description', validators: [Validators.required] },
      { type: 'multiselect', title: 'Category', value: this.submittalCategories, placeholder: 'Select Category...', controlName: 'subCategories', validators: [], valueItems: this.categories },
      { type: 'multiselect', title: 'Product', value: this.submittalProducts, placeholder: 'Select Product...', controlName: 'subProducts', validators: [Validators.required], valueItems: this.products },
      { type: 'select', title: 'Substrate Type', value: this.selectedAppType?.[0]?.id, valueItems: this.appTypes, controlName: 'appTypeId', placeholder: 'Select Substrate Type', validators: [Validators.required] },
      { type: 'checkbox', title: 'Published', value: subPackage ? subPackage.published : '', controlName: 'published', validators: [] },
    ];
    this.fileSettings = [
      { type: 'catFiles', title: 'Files', value: this.files, valueItems: this.fileCategories, controlName: 'catFiles' },
    ]
  }

  setAddForm(subPackage?: SubmittalPackage) {
    this.formSettings = [
      { type: 'input', title: 'Name', value: subPackage ? subPackage.name : '', placeholder: 'Submittal Name...', controlName: 'name', validators: [Validators.required] },
      { type: 'textarea', title: 'Description', value: subPackage ? subPackage.description : '', placeholder: 'Submittal Name...', controlName: 'description', validators: [Validators.required] },
    ];
  }

  handleSave(event: SubmittalPackage) {
    let sub: SubmittalPackage = {
      id: this.subPackage.id,
      name: event.name,
      description: event.description,
      published: event.published
    }
    this.editMode ?
      this.subService.editSubmittal(sub).subscribe(d => { let pckg = new SubmittalPackage(); pckg.id = d.id; this._service.success('Success!', 'Submittal edited!'); this.handleSaveEntities(event, pckg); }, err => { this._service.error('Error!', 'Something went wrong!') }) :
      this.subService.addSubmittal(sub).subscribe(d => { this._service.success('Success!', 'Submittal created!'); this.router.navigate(['', 'submittal-packages', 'edit', d.id]) }, err => { this._service.error('Error!', 'Something went wrong!') })
  }

  handleSaveEntities(event: any, subPack: SubmittalPackage) {
    // this.handleChangedProduct(event, subPack);
    //photos
    this.hs.handleChangeEntity(
      event.photo as Photo[], 
      this.photos, 
      subPack, 
      (b: any) => this.subService.editPhoto(b), 
      (b: any) => this.subService.deletePhoto(b)
    );
    //categories
    this.hs.handleChangeEntity(
      event.subCategories as Category[], 
      this.submittalCategories, 
      subPack, 
      (b: any) => this.subService.addSubmittalCategory(b), 
      (b: any) => this.subService.deleteSubmittalCategory(b)
    );
    //Products
    this.hs.handleChangeEntity(
      event.subProducts as Product[], 
      this.submittalProducts, 
      subPack, 
      (b: any) => this.subService.addSubmittalProduct(b), 
      (b: any) => this.subService.deleteSubmittalProduct(b)
    );
    //appType
    let appType = this.appTypes.find(ap => ap.id == event.appTypeId);
    appType && this.hs.handleChangeEntity(
      [appType] as AppType[], 
      this.selectedAppType, 
      subPack, 
      (b: any) => this.subService.addAppType(b), 
      (b: any) => this.subService.deleteAppType(b)
    );
    
  }

}

import { Component, OnInit } from '@angular/core';
import { NotificationsService } from 'angular2-notifications';
import { SearchService } from 'src/app/api/search.service';

@Component({
  selector: 'app-search-settings',
  templateUrl: './search-settings.component.html',
  styleUrls: ['./search-settings.component.css']
})
export class SearchSettingsComponent implements OnInit {

  constructor(private searchService: SearchService, private notificationService: NotificationsService) { }

  ngOnInit(): void {
  }

  handleRefreshSearchCache() {
    this.searchService.refreshCache().subscribe(x => { this.notificationService.success('Success!', 'Search cache has been refreshed!'); }, err => { this.notificationService.error('Error!', 'Something went wrong!') });
  }
}
<div class="row productListContainer">

    <div class="col-md-12">

        <div class="row">
            <div class="col-md-3 prd-header">
                <h3 class="pull-left">{{header}}</h3>
                <button *ngIf="publishFeature" class="btn btn-outline-primary m-l-md" [ngClass]="{'active': publishState}" (click)="handleChangePublished(!publishState)">Show Only Publish</button>
            </div>
            <div class="form-block col-md-4">
                <form (submit)="handleSearch($event, refToSearch)" *ngIf="showSearch">
                    <input class="search-input col-md-4" type="text" placeholder="Search..." #refToSearch />
                    <button class="btn btn-primary btn-sm handle-search" type="submit">Search</button>
                </form>
            </div>
            <div *ngIf="showDynamics" class="col-md-3 right-block">
                <button class="col-md-6 btn btn-sm btn-info m-r-xs" title="Microsoft Dynamics" (click)="handleLoad()"><img class="microsoft-icon" src="assets/img/732221.png"/>{{microsoftDynamicsLoading ? 'Loading...' : 'Load Products'}}</button>
                <button class="col-md-6 btn btn-sm btn-info" title="Microsoft Dynamics" (click)="handleLoad(true)"><img class="microsoft-icon" src="assets/img/732221.png"/>Update Products</button>
            </div>
            <div *ngIf="addRouterPath" class="col-md-2 right-block">
                <button [routerLink]="addRouterPath" class="col-md-6 btn btn-sm btn-primary pull-right buttonAdd m-b-xs">Add New</button>
            </div>
            <div *ngIf="addFunc" class="col-md-2 right-block">
                <button class="col-md-6 btn btn-sm btn-primary pull-right buttonAdd m-b-xs" (click)="addFunc()">Add New</button>
            </div>
            <div *ngIf="addByInput" class="col-md-2 add-input right-block">
                <input [placeholder]="addInputPlaceholder" #refToAddInput/>
                <button class="col-md-6 btn btn-sm btn-primary pull-right buttonAdd m-b-xs" (click)="addByInput(refToAddInput.value)">Add New</button>
            </div>
            <div class="col-md-3 prd-header m-t-sm">
                <button *ngIf="importAllFeature"
                    (click)="importProductsFile.click()"
					class="btn btn-primary btn-sm m-r-md">
					Import All
				</button>
                <button *ngIf="importAllFeature"
                    class="btn btn-primary btn-sm m-r-md"
                    (click)="exportAllEmitter.emit()">
                    All Export
                </button>
            </div>
            
        </div>
        <div class="row m-t-lg">
            <div class="col-md-12 productList table-responsive">
                <table class="custom-table table table-striped">
                    <thead *ngIf="table?.thead">
                        <tr>
                            <th *ngFor="let th of table?.thead">{{th}}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let tr of table?.tbody; let y = index">
                            <td *ngFor="let td of tr; let i = index">
                                <ng-container *ngIf="!isArray(td) && !isObject(td)">{{td}}</ng-container>
                                <ng-container *ngIf="isObject(td)">
                                    <img *ngIf="td?.type == 'img'" width="50px" height="50px" [src]="td?.url" />
                                    <input *ngIf="td?.type == 'input'" [value]="td.value" (change)="td.change(refToInput.value)" #refToInput />
                                    <input *ngIf="td?.type == 'checkbox'" type="checkbox" [checked]="!!td.value" (change)="td.change(refToCheck.checked)" #refToCheck />
                                    <ng-container *ngIf="td?.type == 'date'">
                                        {{td.value}}
                                    </ng-container>
                                    <ng-container *ngIf="td?.type == 'styledValue'">
                                        <div [ngStyle]="td?.style">
                                            {{td.value}}
                                        </div>
                                    </ng-container>
                                </ng-container>
                                <ng-container *ngIf="isArray(td)">
                                    <ng-container *ngIf="showRegion">
                                        <!-- <app-localize [detail]="table?.titem[y]" [level]="showRegion"></app-localize> -->
                                    </ng-container>

                                    <ng-container *ngFor="let elem of td">
                                      <button class="{{elem.class}}" (click)="elem.callback()">
                                        <img class="microsoft-icon" *ngIf="elem.iconSrc" [src]="elem.iconSrc">
                                      {{elem.label}}
                                      </button>
                                    </ng-container>
                                </ng-container>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <div class="row" *ngIf="totalCount > initialCount">
            <div class="col-md-12">
                <ngb-pagination *ngIf="totalCount" [collectionSize]="totalCount" [pageSize]="initialCount" [(page)]="page" [maxSize]="4" [rotate]="true" [boundaryLinks]="true" (pageChange)="pageChanged()"></ngb-pagination>

            </div>
        </div>

    </div>

</div>
<input #importProductsFile
	(change)="uploadFile($event)"
	type="file"
	class="d-none">
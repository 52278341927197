import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CustomTable } from '../../entities/custom-table/custom-table.component';
import { ApplicationService } from '../../api/application.service';
import { AppType } from '../../models/appType.model';
import { NotificationsService } from 'angular2-notifications';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DeleteModalComponent } from '../../entities/delete-modal/delete-modal.component';

@Component({
  selector: 'app-application',
  templateUrl: './application.component.html',
  styleUrls: ['./application.component.css']
})
export class ApplicationComponent implements OnInit {

  appTable?: CustomTable
  totalCount = 0;
  page = 1;
  count = 40;
  search = '';

  constructor(private appTypeService: ApplicationService, public router: Router, private _service: NotificationsService, private modalService: NgbModal) { }

  ngOnInit(): void {
    this.refreshAppTypes();
  }

  refreshAppTypes() {
    this.appTypeService.getAppTypes(this.search, (this.page - 1) * this.count, this.count).subscribe(cats => {
      this.totalCount = cats.totalCount;
      this.appTable = {
        thead: ['Name', 'Id', 'Actions'],
        tbody:  cats.data.map(i => {
          return [i.name, i.id, [{label: 'Delete', class: 'btn btn-sm btn-danger m-r-md', callback: () => { this.handleDelete(i); }}, {label: 'Edit', class: 'btn btn-sm btn-primary', callback: () => { this.handleEdit(i); }}]];
        })
      };
    })
  }

  handleDelete(cat: AppType) {
    let modalRef = this.modalService.open(DeleteModalComponent);
    modalRef.componentInstance.name = cat.name;
    modalRef.closed.subscribe(r => {
      if(r === 'OK') cat.id && this.appTypeService.deleteAppType(cat.id).subscribe(d => {
        this._service.success('Success!', `${cat.name} substrate type is deleted!`);
        this.refreshAppTypes();
      })
    })
  }

  handleEdit(cat: AppType) {
    this.router.navigate(['', 'app-type', 'edit', cat.id]);
  }

  pageChange(event: number) {
    this.page = event;
    this.refreshAppTypes();
  }

  handleSearch(event: any) {
    this.search = event.value;
    this.page = 1;
    this.refreshAppTypes();
  }

}

import { NgModule, } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';


//3d party modules
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { SimpleNotificationsModule } from 'angular2-notifications';
import { FileUploadModule } from 'ng2-file-upload';
import { CookieService } from 'ngx-cookie-service';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';

//Modules
import { AppRoutingModule } from './app-routing.module';


//Services
import { CategoryService } from './api/category.service';
import { AuthService } from './api/auth.service';
import { ProductService } from './api/product.service';
import { HelperService } from './helpers/helper.service';
import { MainInterceptor } from './main.interceptor';
import { SubmittalService } from './api/submittal.service';
import { CBOService } from './api/cbo.service';
import { SearchService } from './api/search.service';


//Components
import { AppComponent } from './app.component';
import { ProductsComponent } from './pages/products/products.component';
import { CategoriesComponent } from './pages/categories/categories.component';
import { CustomTableComponent } from './entities/custom-table/custom-table.component';
import { FileCategoriesComponent } from './pages/file-categories/file-categories.component';
import { CategoriesEditComponent } from './pages/categories/categories-edit/categories-edit.component';
import { FileCategoriesEditComponent } from './pages/file-categories/file-categories-edit/file-categories-edit.component';
import { CustomFormComponent } from './entities/custom-form/custom-form.component';
import { FormInputComponent } from './entities/custom-form/form-input/form-input.component';
import { FormSelectComponent } from './entities/custom-form/form-select/form-select.component';
import { HeaderComponent } from './entities/header/header.component';
import { ProductsEditComponent } from './pages/products/products-edit/products-edit.component';
import { FormTextComponent } from './entities/custom-form/form-text/form-text.component';
import { FormErrorComponent } from './entities/custom-form/form-error/form-error.component';
import { EditImagesComponent } from './entities/custom-form/form-images/edit-images.component';
import { MainComponent } from './pages/main/main.component';
import { FormFilesComponent } from './entities/custom-form/form-files/form-files.component';
import { DeleteModalComponent } from './entities/delete-modal/delete-modal.component';
import { SubmittalPackageComponent } from './pages/submittal-package/submittal-package.component';
import { SubmittalPackageEditComponent } from './pages/submittal-package/submittal-package-edit/submittal-package-edit.component';
import { FormKeystatsComponent } from './entities/custom-form/form-keystats/form-keystats.component';
import { ApplicationComponent } from './pages/application/application.component';
import { ApplicationEditComponent } from './pages/application/application-edit/application-edit.component';
import { FormCheckComponent } from './entities/custom-form/form-check/form-check.component';
import { FormTreeSelectComponent } from './entities/custom-form/form-tree-select/form-tree-select.component';
import { SelectTreeComponent } from './entities/custom-form/select-tree/select-tree.component';
import { FormStyledTextareaComponent } from './entities/custom-form/form-styled-textarea/form-styled-textarea.component';
import { SearchSettingsComponent } from './pages/search-settings/search-settings.component';
import { CertificationsComponent } from './pages/certifications/certifications.component';
import { CertificationsEditComponent } from './pages/certifications/certifications-edit/certifications-edit.component';
import { ComplaintsComponent } from './pages/complaints/complaints.component';
import { ComplaintsEditComponent } from './pages/complaints/complaints-edit/complaints-edit.component';
import { AnalyticsUsersComponent } from './pages/analytics-users/analytics-users.component';
import { AnalyticsUsersEditComponent } from './pages/analytics-users/analytics-users-edit/analytics-users-edit.component'; 

@NgModule({
  declarations: [
    AppComponent,
    ProductsComponent,
    CategoriesComponent,
    CustomTableComponent,
    FileCategoriesComponent,
    CategoriesEditComponent,
    CustomFormComponent,
    FormInputComponent,
    FormSelectComponent,
    HeaderComponent,
    FileCategoriesEditComponent,
    ProductsEditComponent,
    FormTextComponent,
    FormErrorComponent,
    EditImagesComponent,
    MainComponent,
    FormFilesComponent,
    DeleteModalComponent,
    SubmittalPackageComponent,
    SubmittalPackageEditComponent,
    FormKeystatsComponent,
    ApplicationComponent,
    ApplicationEditComponent,
    FormCheckComponent,
    FormTreeSelectComponent,
    SelectTreeComponent,
    FormStyledTextareaComponent,
    SearchSettingsComponent,
    CertificationsComponent,
    CertificationsEditComponent,
    ComplaintsComponent,
    ComplaintsEditComponent,
    AnalyticsUsersComponent,
    AnalyticsUsersEditComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    AppRoutingModule,
    NgbModule,
    NgSelectModule,
    SimpleNotificationsModule.forRoot(),
    FileUploadModule,
    CKEditorModule,
  ],
  providers: [
    AuthService,
    CategoryService,
    ProductService,
    HelperService,
    SubmittalService,
    CookieService,
    CBOService,
    {
      provide: HTTP_INTERCEPTORS, 
      useClass: MainInterceptor, 
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Validators } from '@angular/forms';
import { FormSettings } from '../../../models/formSettings.model';
import { AppType } from '../../../models/appType.model';
import { ApplicationService } from '../../../api/application.service';
import { NotificationsService } from 'angular2-notifications';

@Component({
  selector: 'app-application-edit',
  templateUrl: './application-edit.component.html',
  styleUrls: ['./application-edit.component.css']
})
export class ApplicationEditComponent implements OnInit {

  editMode = '';
  formSettings: FormSettings[] = [];
  appType = new AppType();

  constructor(private activatedRoute: ActivatedRoute, private appService: ApplicationService, private _service: NotificationsService, private router: Router) { }

  ngOnInit(): void {
    this.editMode = this.activatedRoute.snapshot.params['id'];
    if(this.editMode) {
      this.appService.getAppType(this.editMode).subscribe(appType => {
        this.appType = appType;
        this.setForm(this.appType);
      })
    } else {
      this.setForm();
    }
  }



  setForm(appType?: AppType) {
      this.formSettings = [
        {type: 'input', title: 'Name', value: appType ? appType.name : '', placeholder: 'Substrate Type Name...', controlName: 'name', validators: [Validators.required]},
      ];
  }

  handleSave(event: AppType) {
    let appType: AppType = {
      id: this.appType.id,
      name: event.name,
    }
    this.editMode ? 
    this.appService.editAppType(appType).subscribe(d => { this._service.success('Success!', 'Substrate Type edited!'); this.router.navigate(['', 'app-type']) }, err => { this._service.error('Error!', 'Something went wrong!') }) :
    this.appService.addAppType(appType).subscribe(d => { this._service.success('Success!', 'Substrate Type created!'); this.router.navigate(['', 'app-type']) }, err => { this._service.error('Error!', 'Something went wrong!') })
  }

}

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormTreeItem } from 'src/app/models/formTreeItem';

@Component({
  selector: 'app-select-tree',
  templateUrl: './select-tree.component.html',
  styleUrls: ['./select-tree.component.css']
})
export class SelectTreeComponent implements OnInit {

  @Input() placeholder: string = '';
  @Input() selectedItem?: FormTreeItem;
  @Input() parentItems?: FormTreeItem[] = [];
  @Input() allItems?: FormTreeItem[] = [];
  @Input() subItems?: FormTreeItem[] = [];
  @Output() setItem = new EventEmitter<FormTreeItem>();
  @Output() clearItem = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
    this.subItems = this.allItems?.filter(i => i.parentId === this.selectedItem?.id);
  }

  selectItem(item: FormTreeItem) {
    if (item) {
      this.selectedItem = item;
      this.selectedItem.subItem = new FormTreeItem();
      this.subItems = this.allItems?.filter(i => i.parentId === item?.id);
      this.setItem.emit(this.selectedItem);
    }
  }

  setSubitem(item: FormTreeItem) {
    if (this.selectedItem) {
      this.selectedItem.subItem = item;
      this.setItem.emit(this.selectedItem);
    }
  }

  clear() {
    if (this.selectedItem)
      this.selectedItem.subItem = new FormTreeItem();
  }
}

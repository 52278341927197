import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Complaint } from '../models/complaint.model';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ComplaintService {

  constructor(private http: HttpClient) { }

  getComplaint(id: string) {
    return this.http.get<Complaint>(`${environment.apiUrl}/api/Complaint/AdminGet?id=${id}`);
  }

  getComplaints(q = '', skip = 0, take = 40) {
    return this.http.get<{data: Complaint[], totalCount: number}>(`${environment.apiUrl}/api/Complaint/AdminList?skip=${skip}&take=${take}&query=${q}`);
  }

  addComplaint(body: Complaint) {
    return this.http.post<Complaint>(`${environment.apiUrl}/api/Complaint/Add`, body);
  }

  editComplaint(body: Complaint) {
    return this.http.put<Complaint>(`${environment.apiUrl}/api/Complaint/Edit`, body);
  }

  deleteComplaint(id: string) {
    return this.http.delete<Complaint>(`${environment.apiUrl}/api/Complaint/Delete?id=${id}`);
  }
}

import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CustomTable } from '../../entities/custom-table/custom-table.component';
import { CategoryService } from '../../api/category.service';
import { Category } from 'src/app/models/category.model';
import { NotificationsService } from 'angular2-notifications';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DeleteModalComponent } from '../../entities/delete-modal/delete-modal.component';

@Component({
  selector: 'app-categories',
  templateUrl: './categories.component.html',
  styleUrls: ['./categories.component.css']
})
export class CategoriesComponent implements OnInit {

  appTable?: CustomTable
  totalCount = 0;
  page = 1;
  count = 100;
  search = '';
  categories: Category[] = [];

  constructor(private categoryService: CategoryService, public router: Router, private _service: NotificationsService, private modalService: NgbModal) { }

  ngOnInit(): void {
    this.refreshCats();
  }

  refreshCats() {
    this.categoryService.getCategories(this.search, (this.page - 1) * this.count, this.count).subscribe(cats => {
      this.categories = [];
      this.totalCount = cats.totalCount;
      
      this.formatCategoriesTree(cats.data.filter((category) => !cats.data.find((c) => c.id === category.parentId)), cats.data);

      this.appTable = {
        thead: ['Name', 'Actions'],
        tbody:  this.categories.map(i => {
          return [{type: 'styledValue', value: i.name, style: {'padding-left.px': (i.level ?? 0) * 40}}, [{label: 'Delete', class: 'btn btn-sm btn-danger m-r-md', callback: () => { this.handleDelete(i); }}, {label: 'Edit', class: 'btn btn-sm btn-primary', callback: () => { this.handleEdit(i); }}]];
        })
      };
      
    })
  }

  handleDelete(cat: Category) {
    let modalRef = this.modalService.open(DeleteModalComponent);
    modalRef.componentInstance.name = cat.name;
    modalRef.closed.subscribe(r => {
      if(r === 'OK') cat.id && this.categoryService.deleteCategory(cat.id).subscribe(d => {
        this._service.success('Success!', `${cat.name} category is deleted!`);
        this.refreshCats();
      })
    })
  }

  handleEdit(cat: Category) {
    this.router.navigate(['', 'categories', 'edit', cat.id]);
  }

  pageChange(event: number) {
    this.page = event;
    this.refreshCats();
  }

  handleSearch(event: any) {
    this.search = event.value;
    this.page = 1;
    this.refreshCats();
  }

  formatCategoriesTree(branchCategories: Category[], allCategories: Category[], level: number = 0): void {
    branchCategories.forEach((parentCategory) => {
      let subCategories = allCategories.filter((category) => category.parentId === parentCategory.id);
      parentCategory.level = level;
      this.categories.push(parentCategory);
      if (subCategories) {
        this.formatCategoriesTree(subCategories, allCategories, level + 1);
      }
    });
  }
}

import { Component, OnInit } from '@angular/core';
import { Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NotificationsService } from 'angular2-notifications';
import { ComplaintService } from 'src/app/api/complaint.service';
import { Complaint } from 'src/app/models/complaint.model';
import { FormSettings } from 'src/app/models/formSettings.model';

@Component({
  selector: 'app-complaints-edit',
  templateUrl: './complaints-edit.component.html',
  styleUrls: ['./complaints-edit.component.css']
})
export class ComplaintsEditComponent implements OnInit {
  editMode = '';
  formSettings: FormSettings[] = [];
  complaint = new Complaint();
  allComplaints: Complaint[] = [];

  constructor(
    private activatedRoute: ActivatedRoute,
    private complaintService: ComplaintService,
    private _service: NotificationsService,
    private router: Router) { }

    ngOnInit(): void {
      this.editMode = this.activatedRoute.snapshot.params['id'];
      if(this.editMode) {
        this.complaintService.getComplaint(this.editMode).subscribe(complaint => {
          this.complaint = complaint;
          this.setForm(this.complaint);
        })
      } else {
        this.setForm();
      }
    }
    setForm(complaint?: Complaint) {
      this.complaintService.getComplaints().subscribe(complaints => {
          this.allComplaints = complaints.data;
          this.formSettings = [
            {type: 'input', title: 'Name', value: complaint ? complaint.name : '', placeholder: 'Complaint Name...', controlName: 'name', validators: [Validators.required]},
            {type: 'select', title: 'Parent Complaint', value: complaint ? complaint.parentId : '', valueItems: this.allComplaints, controlName: 'parentId'}
          ];
        })
    }
  
    handleSave(event: Complaint) {
      let cat: Complaint = {
        id: this.complaint.id,
        name: event.name,
        parentId: event.parentId
      }
      this.editMode ? 
      this.complaintService.editComplaint(cat).subscribe(d => { this._service.success('Success!', 'Complaint edited!'); this.router.navigate(['', 'complaints']) }, err => { this._service.error('Error!', 'Something went wrong!') }) :
      this.complaintService.addComplaint(cat).subscribe(d => { this._service.success('Success!', 'Complaint created!'); this.router.navigate(['', 'complaints']) }, err => { this._service.error('Error!', 'Something went wrong!') })
    }
  
}

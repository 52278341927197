<div>
	<app-custom-table [publishFeature]="true"
		[importAllFeature]="true"
		[showSearch]="true"
		[addRouterPath]="['', 'products', 'add']"
		[table]="appTable"
		[header]="'Products'"
		[totalCount]="totalCount"
		[count]="count"
		[showDynamics]="true"
		(publishStateEmitter)="onlyPublished = $event; refreshProds();"
		(search)="handleSearch($event)"
		(pageChange)="pageChange($event)"
		(importAllEmitter)="handleImportAll($event)"
		(exportAllEmitter)="handleExportAll()"></app-custom-table>
</div>

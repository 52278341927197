import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './auth.guard';
import { ProductsComponent } from './pages/products/products.component';
import { CategoriesComponent } from './pages/categories/categories.component';
import { FileCategoriesComponent } from './pages/file-categories/file-categories.component';
import { CategoriesEditComponent } from './pages/categories/categories-edit/categories-edit.component';
import { FileCategoriesEditComponent } from './pages/file-categories/file-categories-edit/file-categories-edit.component';
import { ProductsEditComponent } from './pages/products/products-edit/products-edit.component';
import { MainComponent } from './pages/main/main.component';
import { SubmittalPackageComponent } from './pages/submittal-package/submittal-package.component';
import { SubmittalPackageEditComponent } from './pages/submittal-package/submittal-package-edit/submittal-package-edit.component';
import { ApplicationComponent } from './pages/application/application.component';
import { ApplicationEditComponent } from './pages/application/application-edit/application-edit.component';
import { SearchSettingsComponent } from './pages/search-settings/search-settings.component';
import { CertificationsComponent } from './pages/certifications/certifications.component';
import { CertificationsEditComponent } from './pages/certifications/certifications-edit/certifications-edit.component';
import { ComplaintsComponent } from './pages/complaints/complaints.component';
import { ComplaintsEditComponent } from './pages/complaints/complaints-edit/complaints-edit.component';
import { AnalyticsUsersComponent } from './pages/analytics-users/analytics-users.component';
import { AnalyticsUsersEditComponent } from './pages/analytics-users/analytics-users-edit/analytics-users-edit.component';

const routes: Routes = [
  {path: '', component: MainComponent, canActivate: [AuthGuard], children: [
      { path: '', redirectTo: 'products', pathMatch: 'full' },
      { path: 'products', component: ProductsComponent },
      { path: 'products/edit/:id', component: ProductsEditComponent },
      { path: 'products/add', component: ProductsEditComponent },
      { path: 'app-type', component: ApplicationComponent },
      { path: 'app-type/edit/:id', component: ApplicationEditComponent },
      { path: 'app-type/add', component: ApplicationEditComponent },
      { path: 'categories', component: CategoriesComponent },
      { path: 'categories/edit/:id', component: CategoriesEditComponent },
      { path: 'categories/add', component: CategoriesEditComponent },
      { path: 'certifications', component: CertificationsComponent },
      { path: 'certifications/edit/:id', component: CertificationsEditComponent },
      { path: 'certifications/add', component: CertificationsEditComponent },
      { path: 'file-categories', component: FileCategoriesComponent },
      { path: 'file-categories/edit/:id', component: FileCategoriesEditComponent },
      { path: 'file-categories/add', component: FileCategoriesEditComponent },
      { path: 'submittal-packages', component: SubmittalPackageComponent },
      { path: 'submittal-packages/edit/:id', component: SubmittalPackageEditComponent, data: { submittal: true } },
      { path: 'submittal-packages/add', component: SubmittalPackageEditComponent },
      { path: 'search-settings', component: SearchSettingsComponent },
      { path: 'complaints', component: ComplaintsComponent },
      { path: 'complaints/edit/:id', component: ComplaintsEditComponent },
      { path: 'complaints/add', component: ComplaintsEditComponent },
      { path: 'analytics-users', component: AnalyticsUsersComponent },
      { path: 'analytics-users/edit/:id', component: AnalyticsUsersEditComponent },
      { path: 'analytics-users/add', component: AnalyticsUsersEditComponent },
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }

<div class="form-files-wrapper" [formGroup]="pForm">
    <h4 *ngIf="fs?.title" class="m-t-lg m-b-md">{{fs?.title}}</h4>
    <div class="form-files-block">
        <table class="table table-striped">
            <thead>
                <tr>
                    <th scope="col">File Name</th>
                    <th scope="col">Microsoft Dynamics Id</th>
                    <th scope="col">Title</th>
                    <th scope="col">Sync Url</th>
                    <th scope="col">File Category</th>
                    <th scope="col">Published</th>
                    <th scope="col">Download Url</th>
                    <th scope="col">Actions</th>
                </tr>
            </thead>
            <tbody>
                <ng-container formArrayName="catFiles">
                    <ng-container *ngFor="let item of control?.controls; let i = index">
                        <tr>
                            <td>{{item?.value?.fileName}}</td>
                            <td>{{item?.value?.microsoftDynamicsId}}</td>
                            <td>
                                <app-form-input [settings]="getSettings('title')" [parentForm]="item"></app-form-input>
                            </td>
                            <td>
                                <app-form-input [focusOnClick]="true" *ngIf="item?.value?.syncUrlMode" [settings]="getSettings('syncUrl')" [parentForm]="item"></app-form-input>
                            </td>
                            <td>
                                <app-form-select [settings]="getSettings('fileCategory')" [parentForm]="item"></app-form-select>
                            </td>
                            <td>
                                <app-form-check [settings]="getSettings('published')" [parentForm]="item"></app-form-check>
                            </td>
                            <td><a *ngIf="item?.value?.url || item?.value?.syncUrl" [href]="item?.value?.url || item?.value?.syncUrl" target="_blank">Download Url</a></td>
                            <td>
                                <button class="btn btn-sm btn-danger" type="button" (click)="handleDeleteFile(item?.value?.title || item?.value?.fileName, item?.value?.id)">Delete</button>
                            </td>
                        </tr>
                    </ng-container>
                </ng-container>

            </tbody>
        </table>
    </div>
    <div>
        <div ng2FileDrop [ngClass]="{'nv-file-over': hasBaseDropZoneOver}" (fileOver)="fileOverBase($event)" [uploader]="uploader" class="well my-drop-zone" (onFileDrop)="changedFile($event)">
            File Drop Zone
        </div>
        <button class="btn btn-sm btn-secondary" type="button" (click)="handleAddFile(true)">Add File By Url</button>
    </div>
</div>
import { Injectable } from '@angular/core';
import { Validators, AbstractControl } from '@angular/forms';
import { FileUploader } from 'ng2-file-upload';
import * as _ from 'lodash';
import { Product } from '../models/product.model';
import { SubmittalPackage } from '../models/submittal.model';
import { Certification } from '../models/certification.model';

@Injectable({
  providedIn: 'root'
})
export class HelperService {

  constructor() { }

  checkTouched(currControl?: AbstractControl) {
    return currControl?.touched && currControl.invalid;
  }

  getReq(currControl?: AbstractControl) {
    return currControl?.hasValidator(Validators.required);
  }

  uploadQueue(uploader: FileUploader, callback = (data: any) => { }, onLoadStart = () => { }, onLoadEnd = () => { }, counter = 0) {
    counter == 0 ? onLoadStart() : '';
    if (uploader.queue.length > 0 && counter != uploader.queue.length) {
      let uploadItem = uploader.queue[counter];
      if (!uploadItem.isUploaded) {
        uploadItem.withCredentials = false;
        uploadItem.upload();
        uploadItem._xhr.onloadend = () => {
          let data = JSON.parse(uploadItem._xhr.response);
          callback(data);
          counter++;
          this.uploadQueue(uploader, callback, onLoadStart, onLoadEnd, counter);
        }
      } else {
        counter++
        this.uploadQueue(uploader, callback, onLoadStart, onLoadEnd, counter);
      }
    } else {
      onLoadEnd();
      return true;
    }
    return;
  }

    handleChangeEntity<T extends { id?: string, entityId?: string }>(entities: T[], productEntities: T[], item: Product | SubmittalPackage | Certification, addFunc: Function, delFunc: Function) {
    let diffToAdd = _.differenceBy(entities, productEntities, 'id');
    let diffToDel = _.differenceBy(productEntities, entities, 'id');
    productEntities.splice(0, productEntities.length);
    entities.forEach(e => productEntities.push(e));
    diffToAdd.forEach(diffItem => {
      let body: T = { ...diffItem };
      if (item instanceof SubmittalPackage) {
        (body as any)['submittalPackageId'] = item.id;
      } else if (item instanceof Certification) (body as any)['certificationId'] = item.id;
      else (body as any)['productId'] = item.id;
      item?.id && addFunc(body).subscribe((d: T) => {
        let item = productEntities.find(c => c.id == diffItem.id);
        if (item) {
          item.entityId = d.id;
          item.id = item.id || item.entityId;
        }
      });
    })
    diffToDel.forEach(diffItem => {
      (diffItem.entityId || diffItem.id) && delFunc(diffItem.entityId || diffItem.id).subscribe((d: T) => { });
    })
  }

}

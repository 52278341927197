import { ProductFile } from './file.model'
export class Category {
    id: string = '';
    name: string = '';
    parentId?: string;
    entityId?: string;
    level?: number = 0;
}

export class FileCategory {
    id: string = '';
    name: string = '';
    parentId?: string;
    cboLanguageId?: number;
}
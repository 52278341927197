<div>
	<app-custom-table [showSearch]="true"
		[addRouterPath]="['', 'certifications', 'add']"
		[table]="appTable"
		[header]="'Certifications'"
		[totalCount]="totalCount"
		[count]="count"
		(search)="handleSearch($event)"
		(pageChange)="pageChange($event)"></app-custom-table>
</div>

import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CustomTable } from '../../entities/custom-table/custom-table.component';
import { Product } from '../../models/product.model';
import { ProductService } from '../../api/product.service';
import { NotificationsService } from 'angular2-notifications';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DeleteModalComponent } from '../../entities/delete-modal/delete-modal.component';
import { saveAs } from 'file-saver';

@Component({
  selector: 'app-products',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.css']
})
export class ProductsComponent implements OnInit {

  appTable?: CustomTable
  totalCount = 0;
  page = 1;
  count = 40;
  search = '';
  onlyPublished = true;

  constructor(private productService: ProductService, public router: Router, private _service: NotificationsService, private modalService: NgbModal) { }

  ngOnInit(): void {
    this.refreshProds();
  }

  refreshProds() {
    this.productService.getProducts(this.search, (this.page - 1) * this.count, this.count, this.onlyPublished).subscribe(prods => {
      this.totalCount = prods.totalCount;
      this.appTable = {
        thead: ['Name', 'Id', 'Published', 'Actions'],
        tbody: prods.data.map(i => {
          let body: any = [i.name, i.id, { type: 'checkbox', value: !!i.published, change: (state: boolean) => { this.handlePublish(i, state); } }, [{ label: 'Delete', class: 'btn btn-sm btn-danger m-r-md', callback: () => { this.handleDelete(i); } }, { label: 'Edit', class: 'btn btn-sm btn-primary m-r-md', callback: () => { this.handleEdit(i); } }]];
          if (i.microsoftDynamicsId) body[3].push({ iconSrc: 'assets/img/732221.png', label: 'Update', class: 'btn btn-sm btn-info', callback: () => { this.handleUpdate(i); } });
          return body;
        })
      };
    })
  }

  handlePublish(product: Product, state: boolean) {
    product?.id && this.productService.changePublished({ productId: product.id, published: state }).subscribe(data => {
      this.refreshProds();
    })
  }

  handleDelete(prod: Product) {
    let modalRef = this.modalService.open(DeleteModalComponent);
    modalRef.componentInstance.name = prod.name;
    modalRef.closed.subscribe(r => {
      if (r === 'OK') prod.id && this.productService.deleteProduct(prod.id).subscribe(d => {
        this._service.success('Success!', `${prod.name} product is deleted!`);
        this.refreshProds();
      })
    })
  }

  handleEdit(prod: Product) {
    this.router.navigate(['', 'products', 'edit', prod.id]);
  }

  handleUpdate(prod: Product) {
    confirm('Are you sure want to update product from Microsoft Dynamics? This will result in rewriting some of product fields!') && this.productService.updateProduct(prod.id).subscribe(() => {
      this._service.success('Success', 'Product is updated!');
    }, err => this._service.error('Error', 'Something went wrong!'));
  }

  pageChange(event: number) {
    this.page = event;
    this.refreshProds();
  }

  handleSearch(event: any) {
    this.search = event.value;
    this.page = 1;
    this.refreshProds();
  }

  handleImportAll(file: any) {
    this.productService.importAllProducts(file).subscribe(() => {
      this._service.success('Success', 'Products have been uploaded to the system.');
    }, err => {
      this._service.error('Error', `Something went wrong! ${err?.error}`)
    });
  }


  handleExportAll() {
    this.productService.exportAllProducts().subscribe((data: Blob) => {
      saveAs(data, `products-${Date.now().toString()}.xlsx`);
    }, err => this._service.error('Error', `Something went wrong! ${err}`));
  }
}

<div *ngIf="parentItems?.length"
	class="select-wrapper">
	<div class="select-item">
		<ng-select [placeholder]="placeholder"
			[items]="parentItems || []"
			[(ngModel)]="selectedItem"
			[ngModelOptions]="{standalone: true}"
			(click)="$event.stopImmediatePropagation()"
			(change)="selectItem($event)"
            (clear)="clearItem.emit()"
			bindLabel="name">
		</ng-select>
	</div>
	<ng-container *ngIf="selectedItem?.subItem">
		<app-select-tree [selectedItem]="selectedItem?.subItem"
			[placeholder]="placeholder"
			[parentItems]="subItems"
			[allItems]="allItems"
			(setItem)="setSubitem($event)"
            (clearItem)="clear()">
		</app-select-tree>
	</ng-container>
</div>

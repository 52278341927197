import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { SubmittalPackage, SubmittalProduct, SubmittalProductCategory } from '../models/submittal.model';
import { Photo } from '../models/photo.model';
import { ProductFile } from '../models/file.model';
import { AppType } from '../models/appType.model';

@Injectable({
  providedIn: 'root'
})
export class SubmittalService {

  constructor(private http: HttpClient) { }

  getSubmittal(id: string) {
    return this.http.get<SubmittalPackage>(`${environment.apiUrl}/api/SubmittalPackage/AdminGet?id=${id}`);
  }

  getSubmittals(q = '', skip = 0, take = 40) {
    return this.http.get<{data: SubmittalPackage[], totalCount: number}>(`${environment.apiUrl}/api/SubmittalPackage/AdminList?skip=${skip}&take=${take}&query=${q}`);
  }

  changePublished(body: {submittalPackageId: string, published: boolean}) {
    return this.http.post<SubmittalPackage>(`${environment.apiUrl}/api/SubmittalPackage/ChangePublished`, body);
  }

  addSubmittal(body: SubmittalPackage) {
    return this.http.post<SubmittalPackage>(`${environment.apiUrl}/api/SubmittalPackage/Add`, body);
  }

  editSubmittal(body: SubmittalPackage) {
    return this.http.put<SubmittalPackage>(`${environment.apiUrl}/api/SubmittalPackage/Edit`, body);
  }

  deleteSubmittal(id: string) {
    return this.http.delete<SubmittalPackage>(`${environment.apiUrl}/api/SubmittalPackage/Delete?id=${id}`);
  }

  deleteSubmittalProduct(id: string) {
    return this.http.delete<SubmittalProduct>(`${environment.apiUrl}/api/SubmittalPackageProduct/Delete?id=${id}`);
  }

  addSubmittalProduct(body: SubmittalProduct) {
    let tempBody = {
      ...body,
      productId: body.id
    }
    return this.http.post<SubmittalProduct>(`${environment.apiUrl}/api/SubmittalPackageProduct/Add`, tempBody);
  }

  getSubmittalProduct(submittalPackageId?: string) {
    return this.http.get<SubmittalProduct[]>(`${environment.apiUrl}/api/SubmittalPackageProduct/List?submittalPackageId=${submittalPackageId}`);
  }


  deleteSubmittalCategory(id: string) {
    return this.http.delete<SubmittalProductCategory>(`${environment.apiUrl}/api/SubmittalPackageProductCategory/Delete?id=${id}`);
  }

  addSubmittalCategory(body: SubmittalProductCategory) {
    let tempBody = {
      ...body,
      productCategoryId: body.id
    }
    return this.http.post<SubmittalProductCategory>(`${environment.apiUrl}/api/SubmittalPackageProductCategory/Add`, tempBody);
  }

  getSubmittalCategory(submittalPackageId?: string) {
    return this.http.get<SubmittalProductCategory[]>(`${environment.apiUrl}/api/SubmittalPackageProductCategory/List?submittalPackageId=${submittalPackageId}`);
  }

  getPhotos(sumbittalPackageId: string) {
    return this.http.get<{data: Photo[], totalCount: number}>(`${environment.apiUrl}/api/Photo/AdminList?sumbittalPackageId=${sumbittalPackageId}`);
  }

  editPhoto(body: {id: string; submittalPackageId: string}) {
    return this.http.put<Photo>(`${environment.apiUrl}/api/Photo/Edit`, body);
  }

  deletePhoto(id: string) {
    return this.http.delete<Photo>(`${environment.apiUrl}/api/Photo/Delete?id=${id}`);
  }


  getProductFiles(submittalPackageId: string) {
    return this.http.get<{data: ProductFile[], totalCount: number}>(`${environment.apiUrl}/api/File/AdminList?submittalPackageId=${submittalPackageId}`);
  }

  deleteProductFile(id: string) {
    return this.http.delete(`${environment.apiUrl}/api/File/Delete?id=${id}`);
  }

  editFile(body: ProductFile) {
    return this.http.put<ProductFile>(`${environment.apiUrl}/api/File/Edit`, body);
  }

  addFileByUrl(url: string) {
    return this.http.post<ProductFile>(`${environment.apiUrl}/api/File/UploadByUrl?url=${url}`, {});
  }

  deleteAppType(id: string) {
    return this.http.delete<AppType>(`${environment.apiUrl}/api/ApplicationTypeSubmittalPackage/Delete?id=${id}`);
  }

  addAppType(body: AppType) {
    let tempBody = {
      ...body,
      applicationTypeId: body.id
    }
    return this.http.post<AppType>(`${environment.apiUrl}/api/ApplicationTypeSubmittalPackage/Add`, tempBody);
  }

  getAppTypes(submittalPackageId?: string) {
    return this.http.get<AppType[]>(`${environment.apiUrl}/api/ApplicationTypeSubmittalPackage/List?submittalPackageId=${submittalPackageId}`);
  }
}

<label [for]="formSetting.controlName"
	[formGroup]="getFormGroup()">
	<div *ngIf="formSetting?.title">
		{{formSetting.title}}{{hs.getReq(control) ? '*' : ''}}:
	</div>
	<div *ngFor="let item of formSetting.value; let i = index">
		<div class="d-flex">
			<app-select-tree [selectedItem]="item"
				[parentItems]="availableItems"
				[allItems]="formSetting.valueItems"
				[placeholder]="formSetting.placeholder || ''"
				(setItem)="selectItem(i, $event)"
				(clearItem)="handleDelete(i)">
			</app-select-tree>
			<button (click)="handleDelete(i)"
				class="btn btn-sm btn-danger delete-btn"
				type="button">
				Remove
			</button>
		</div>
	</div>
	<button (click)="handleAdd()"
		class="btn btn-sm btn-secondary"
		type="button">
		Add {{formSetting?.title}}
	</button>
</label>
<app-form-error [control]="control"></app-form-error>

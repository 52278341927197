import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Category, FileCategory } from '../models/category.model';

@Injectable({
  providedIn: 'root'
})
export class CategoryService {

  constructor(private http: HttpClient) { }

  getCategory(id: string) {
    return this.http.get<Category>(`${environment.apiUrl}/api/ProductCategory/AdminGet?id=${id}`);
  }

  getCategories(q = '', skip = 0, take = 40) {
    return this.http.get<{data: Category[], totalCount: number}>(`${environment.apiUrl}/api/ProductCategory/AdminList?skip=${skip}&take=${take}&query=${q}`);
  }

  addCategory(body: Category) {
    return this.http.post<Category>(`${environment.apiUrl}/api/ProductCategory/Add`, body);
  }

  editCategory(body: Category) {
    return this.http.put<Category>(`${environment.apiUrl}/api/ProductCategory/Edit`, body);
  }

  deleteCategory(id: string) {
    return this.http.delete<Category>(`${environment.apiUrl}/api/ProductCategory/Delete?id=${id}`);
  }


  getFileCategory(id: string) {
    return this.http.get<FileCategory>(`${environment.apiUrl}/api/FileCategory/AdminGet?id=${id}`);
  }

  getFileCategories(q = '', skip = 0, take = 40) {
    return this.http.get<{data: FileCategory[], totalCount: number}>(`${environment.apiUrl}/api/FileCategory/AdminList?skip=${skip}&take=${take}&query=${q}`);
  }

  addFileCategory(body: FileCategory) {
    return this.http.post<FileCategory>(`${environment.apiUrl}/api/FileCategory/Add`, body);
  }

  editFileCategory(body: FileCategory) {
    return this.http.put<FileCategory>(`${environment.apiUrl}/api/FileCategory/Edit`, body);
  }

  deleteFileCategory(id: string) {
    return this.http.delete<FileCategory>(`${environment.apiUrl}/api/FileCategory/Delete?id=${id}`);
  }

}

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Product, ProductCategory, ProductComplaint } from '../models/product.model';
import { AppType } from '../models/appType.model';
import { ProductFile } from '../models/file.model';
import { Photo } from '../models/photo.model';
import { Keystat } from '../models/keystat.model';
import { Certification } from '../models/certification.model';

@Injectable({
  providedIn: 'root'
})
export class ProductService {

  constructor(private http: HttpClient) { }

  getProduct(id: string) {
    return this.http.get<Product>(`${environment.apiUrl}/api/Product/AdminGet?id=${id}`);
  }

  getProducts(q = '', skip = 0, take = 40, onlyPublished = false) {
    return this.http.get<{data: Product[], totalCount: number}>(`${environment.apiUrl}/api/Product/AdminList?skip=${skip}&take=${take}&query=${q}&onlyPublished=${onlyPublished}`);
  }

  changePublished(body: {productId: string, published: boolean}) {
    return this.http.post<Product>(`${environment.apiUrl}/api/Product/ChangePublished`, body);
  }

  addProduct(body: Product) {
    return this.http.post<Product>(`${environment.apiUrl}/api/Product/Add`, body);
  }

  editProduct(body: Product) {
    return this.http.put<Product>(`${environment.apiUrl}/api/Product/Edit`, body);
  }

  deleteProduct(id: string) {
    return this.http.delete<Product>(`${environment.apiUrl}/api/Product/Delete?id=${id}`);
  }

  getPhotos(productId: string) {
    return this.http.get<{data: Photo[], totalCount: number}>(`${environment.apiUrl}/api/Photo/AdminList?productId=${productId}`);
  }

  editPhoto(body: Photo) {
    return this.http.put<Photo>(`${environment.apiUrl}/api/Photo/Edit`, body);
  }

  deletePhoto(id: string) {
    return this.http.delete<Photo>(`${environment.apiUrl}/api/Photo/Delete?id=${id}`);
  }

  deleteProductCategory(id: string) {
    return this.http.delete<ProductCategory>(`${environment.apiUrl}/api/ProductProductCategory/Delete?id=${id}`);
  }

  addProductCategory(body: ProductCategory) {
    let tempBody = {
      ...body,
      productCategoryId: body.id
    }
    return this.http.post<ProductCategory>(`${environment.apiUrl}/api/ProductProductCategory/Add`, tempBody);
  }

  getProductCategories(productId?: string) {
    return this.http.get<ProductCategory[]>(`${environment.apiUrl}/api/ProductProductCategory/List?productId=${productId}`);
  }


  deleteAppType(id: string) {
    return this.http.delete<AppType>(`${environment.apiUrl}/api/ApplicationTypeProduct/Delete?id=${id}`);
  }

  addAppType(body: AppType) {
    let tempBody = {
      ...body,
      applicationTypeId: body.id
    }
    return this.http.post<AppType>(`${environment.apiUrl}/api/ApplicationTypeProduct/Add`, tempBody);
  }

  getAppTypes(productId?: string) {
    return this.http.get<AppType[]>(`${environment.apiUrl}/api/ApplicationTypeProduct/List?productId=${productId}`);
  }


  getProductFiles(productId: string) {
    return this.http.get<{data: ProductFile[], totalCount: number}>(`${environment.apiUrl}/api/File/AdminList?productId=${productId}`);
  }

  deleteProductFile(id: string) {
    return this.http.delete(`${environment.apiUrl}/api/File/Delete?id=${id}`);
  }

  editFile(body: ProductFile) {
    return this.http.put<ProductFile>(`${environment.apiUrl}/api/File/Edit`, body);
  }

  addFileByUrl(url: string) {
    return this.http.post<ProductFile>(`${environment.apiUrl}/api/File/UploadByUrl?url=${url}`, {});
  }


  deleteKeyStat(id: string) {
    return this.http.delete<Keystat>(`${environment.apiUrl}/api/KeyStat/Delete?id=${id}`);
  }

  addKeyStat(body: Keystat) {
    return this.http.post<Keystat>(`${environment.apiUrl}/api/KeyStat/Add`, body);
  }

  editKeyStat(body: Keystat) {
    return this.http.post<Keystat>(`${environment.apiUrl}/api/KeyStat/Edit`, body);
  }

  getKeyStats(productId?: string) {
    return this.http.get<{data: Keystat[], totalCount: number}>(`${environment.apiUrl}/api/KeyStat/List?productId=${productId}`);
  }

  loadProducts(result: boolean) {
    return this.http.get(`${environment.apiUrl}/api/MicrosoftDynamics/LoadProducts?onlyPublished=${result}`);
  }

  updateProducts() {
    return this.http.put(`${environment.apiUrl}/api/MicrosoftDynamics/UpdateProducts`, {});
  }

  updateProduct(productId?: string) {
    return this.http.put(`${environment.apiUrl}/api/MicrosoftDynamics/UpdateProduct?id=${productId}`, {});
  }

  importAllProducts(file: any) {
    const formData = new FormData();
    formData.append('file', file);
    return this.http.post(`${environment.apiUrl}/api/Product/ExcelImport`, formData);
  }

  exportAllProducts() {
    return this.http.get(`${environment.apiUrl}/api/Product/ExcelExport`, { responseType: 'blob' });
  }

  getProductCertifications(productId: string) {
    return this.http.get<{data: ProductFile[], totalCount: number}>(`${environment.apiUrl}/api/ProductCertification/List?productId=${productId}`);
  }

  addProductCertification(body: Certification) {
    let tempBody = {
      ...body,
      certificationId: body.id
    }
    return this.http.post<Certification>(`${environment.apiUrl}/api/ProductCertification/Add`, tempBody);
  }

  deleteProductCertification(id: string) {
    return this.http.delete<Certification>(`${environment.apiUrl}/api/ProductCertification/Delete?id=${id}`);
  }

  deleteProductComplaint(id: string) {
    return this.http.delete<ProductComplaint>(`${environment.apiUrl}/api/ProductComplaint/Delete?id=${id}`);
  }

  addProductComplaint(body: ProductComplaint) {
    let tempBody = {
      ...body,
      complaintId: body.id
    }
    return this.http.post<ProductComplaint>(`${environment.apiUrl}/api/ProductComplaint/Add`, tempBody);
  }

  getProductComplaints(productId?: string) {
    return this.http.get<ProductComplaint[]>(`${environment.apiUrl}/api/ProductComplaint/List?productId=${productId}`);
  }
}

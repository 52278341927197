import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CustomTable } from '../../entities/custom-table/custom-table.component';
import { SubmittalService } from '../../api/submittal.service';
import { SubmittalPackage } from '../../models/submittal.model';
import { NotificationsService } from 'angular2-notifications';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DeleteModalComponent } from '../../entities/delete-modal/delete-modal.component';

@Component({
  selector: 'app-submittal-package',
  templateUrl: './submittal-package.component.html',
  styleUrls: ['./submittal-package.component.css']
})
export class SubmittalPackageComponent implements OnInit {

  appTable?: CustomTable
  totalCount = 0;
  page = 1;
  count = 40;
  search = '';

  constructor(private subService: SubmittalService, public router: Router, private _service: NotificationsService, private modalService: NgbModal) { }

  ngOnInit(): void {
    this.refreshSub();
  }

  refreshSub() {
    this.subService.getSubmittals(this.search, (this.page - 1) * this.count, this.count).subscribe(cats => {
      this.totalCount = cats.totalCount;
      this.appTable = {
        thead: ['Name', 'Id',  'Published', 'Actions'],
        tbody: cats.data.map(i => {
          return [i.name, i.id, {type: 'checkbox', value: !!i.published, change: (state: boolean) => { this.handlePublish(i, state); }}, [{ label: 'Delete', class: 'btn btn-sm btn-danger m-r-md', callback: () => { this.handleDelete(i); } }, { label: 'Edit', class: 'btn btn-sm btn-primary', callback: () => { this.handleEdit(i); } }]];
        })
      };
    })
  }

  handlePublish(subm: SubmittalPackage, state: boolean) {
    subm?.id && this.subService.changePublished({submittalPackageId: subm.id, published: state}).subscribe(data => {
      this.refreshSub();
    })
  }

  handleDelete(cat: SubmittalPackage) {
    let modalRef = this.modalService.open(DeleteModalComponent);
    modalRef.componentInstance.name = cat.name;
    modalRef.closed.subscribe(r => {
      if (r === 'OK') cat.id && this.subService.deleteSubmittal(cat.id).subscribe(d => {
        this._service.success('Success!', `${cat.name} submittial is deleted!`);
        this.refreshSub();
      })
    })
  }

  handleEdit(cat: SubmittalPackage) {
    this.router.navigate(['', 'submittal-packages', 'edit', cat.id]);
  }

  pageChange(event: number) {
    this.page = event;
    this.refreshSub();
  }

  handleSearch(event: any) {
    this.search = event.value;
    this.page = 1;
    this.refreshSub();
  }

}

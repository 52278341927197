import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Validators } from '@angular/forms';
import { FormSettings } from '../../../models/formSettings.model';
import { Product, ProductCategory, ProductComplaint } from '../../../models/product.model';
import { Photo } from '../../../models/photo.model';
import { Category, FileCategory } from '../../../models/category.model';
import { ProductFile } from '../../../models/file.model';
import { Keystat } from '../../../models/keystat.model';
import { ProductService } from '../../../api/product.service';
import { CategoryService } from '../../../api/category.service';
import { ApplicationService } from '../../../api/application.service';
import { HelperService } from '../../../helpers/helper.service';
import { NotificationsService } from 'angular2-notifications';
import { forkJoin } from 'rxjs';
import * as _ from 'lodash';
import { AppType, AppTypeProduct } from 'src/app/models/appType.model';
import { FormTreeItem } from 'src/app/models/formTreeItem';
import { CertificationService } from 'src/app/api/certification.service';
import { Certification, ProductCertification } from 'src/app/models/certification.model';
import { ComplaintService } from 'src/app/api/complaint.service';
import { Complaint } from 'src/app/models/complaint.model';

@Component({
  selector: 'app-products-edit',
  templateUrl: './products-edit.component.html',
  styleUrls: ['./products-edit.component.css']
})
export class ProductsEditComponent implements OnInit {

  editMode = '';
  formSettings: FormSettings[] = [];
  fileSettings: FormSettings[] = [];
  product = new Product();
  photos: Photo[] = [];
  productCategories: Category[] = [];
  categories: Category[] = [];
  categoryFormTreeItems: FormTreeItem[] = [];
  fileCategories: FileCategory[] = [];
  files: ProductFile[] = [];
  keystats: Keystat[] = [];
  productAppTypes: AppType[] = [];
  appTypes: AppType[] = [];
  certifications: Certification[] = [];
  productCertifications: Certification[] = [];
  productComplaints: Complaint[] = [];
  complaints: Complaint[] = [];
  complaintsFormTreeItems: FormTreeItem[] = [];

  constructor(
    private activatedRoute: ActivatedRoute,
    private productService: ProductService,
    private _service: NotificationsService,
    private router: Router,
    private categoryService: CategoryService,
    private hs: HelperService,
    private appTypeService: ApplicationService,
    private certificationService: CertificationService,
    private complaintService: ComplaintService
  ) { }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe(params => {
      this.editMode = this.activatedRoute.snapshot.params['id'];
      if (this.editMode) {
        forkJoin(
          this.productService.getProductCategories(this.editMode),
          this.categoryService.getCategories('', 0, 2000),
          this.productService.getPhotos(this.editMode),
          this.productService.getProduct(this.editMode),
          this.productService.getProductFiles(this.editMode),
          this.categoryService.getFileCategories('', 0, 2000),
          this.productService.getKeyStats(this.editMode),
          this.productService.getAppTypes(this.editMode),
          this.appTypeService.getAppTypes('', 0, 2000),
          this.productService.getProductCertifications(this.editMode),
          this.certificationService.getCertifications('', 0, 2000),
          this.productService.getProductComplaints(this.editMode),
          this.complaintService.getComplaints('', 0, 2000)
        ).subscribe((data) => {
          let prodCat = data[0];
          this.categories = data[1].data;
          this.photos = data[2].data;
          this.product = data[3];
          this.files = data[4].data;
          this.fileCategories = data[5].data;
          this.keystats = data[6].data;
          let appTypesSelected = data[7];
          this.appTypes = data[8].data;
          let productCertifications = data[9];
          this.certifications = data[10].data;
          let prodComp = data[11];
          this.complaints = data[12].data;       

          this.productCategories = [];

          appTypesSelected.forEach((ap: AppTypeProduct) => {
            let exist = this.appTypes.find(c => c.id === ap.applicationTypeId);
            exist && this.productAppTypes.push({ ...exist, entityId: ap.id });
          })

          prodCat.forEach((prodCatItem: ProductCategory) => {
            let exist = this.categories.find(c => c.id === prodCatItem.productCategoryId);
            exist && this.productCategories.push({ ...exist, entityId: prodCatItem.id });
          })

          productCertifications.forEach((productCertification: ProductCertification) => {
            let exist = this.certifications.find(c => c.id === productCertification.certificationId);
            exist && this.productCertifications.push({ ...exist, entityId: productCertification.id });
          })

          prodComp.forEach((prodCompItem: ProductComplaint) => {
            let exist = this.complaints.find(c => c.id === prodCompItem.complaintId);
            exist && this.productComplaints.push({ ...exist, entityId: prodCompItem.id });
          })
          this.setEditForm(this.product);
        })
      } else {
        this.setAddForm();
      }
    })
  }

  setEditForm(prod?: Product) {
    let productCategoriesTree: FormTreeItem[] = this.productCategories.filter((category) => !this.productCategories.some(c => category.parentId === c.id))
      .map((category) => {
        return { id: category.id, parentId: category.parentId, name: category.name }
      });
    productCategoriesTree.forEach(productCategory => {
      this.formatCategoriesFormTree(productCategory, this.productCategories);
    });

    let productComplaintsTree: FormTreeItem[] = this.productComplaints.filter((complaint) => !this.productComplaints.some(c => complaint.parentId === c.id))
      .map((complaint) => {
        return { id: complaint.id, parentId: complaint.parentId, name: complaint.name }
      });
    productComplaintsTree.forEach(productComplaint => {
      this.formatCategoriesFormTree(productComplaint, this.productComplaints);
    });

    this.formSettings = [
      { type: 'photos', title: '', value: this.photos, controlName: 'photo', validators: [Validators.required, Validators.maxLength(1)] },
      { type: 'input', title: 'Name', value: prod ? prod.name : '', placeholder: 'Product Name...', controlName: 'name', validators: [Validators.required] },
      { type: 'textarea', title: 'Description', value: prod ? prod.description : '', placeholder: 'Description Name...', controlName: 'description', validators: [Validators.required] },
      { type: 'textarea', title: 'Product Page link', value: prod ? prod.pageUrl : '', placeholder: 'Product Page link...', controlName: 'pageUrl', validators: [] },
      { type: 'input', title: 'Vanity Url', value: prod ? prod.vanityUrl : '', placeholder: 'Vanity Url...', controlName: 'vanityUrl', validators: [Validators.required, Validators.maxLength(255)] },
      { type: 'input', title: 'External Id', value: prod ? prod.externalId : '', placeholder: 'External Id...', controlName: 'externalId', validators:[Validators.maxLength(100)] },
      { type: 'selectTree', title: 'Category', value: productCategoriesTree, placeholder: 'Select Category...', controlName: 'prodCategories', valueItems: this.categories, validators: [Validators.required] },
      { type: 'multiselect', title: 'App Type', value: this.productAppTypes, placeholder: 'Select App Type...', controlName: 'prodAppTypes', validators: [], valueItems: this.appTypes },
      { type: 'multiselect', title: 'Certifications', value: this.productCertifications, placeholder: 'Select Certeficate...', controlName: 'prodCertifications', validators: [], valueItems: this.certifications },
      { type: 'selectTree', title: 'Complaint', value: productComplaintsTree, placeholder: 'Select Complaint...', controlName: 'prodComplaints', valueItems: this.complaints },
      { type: 'checkbox', title: 'Published', value: prod ? prod.published : '', controlName: 'published', validators: [] },
      { type: 'keystats', title: 'Technical Data', value: this.keystats, controlName: 'keystats', valueItems: this.categories },
      { type: 'styledTextarea', title: 'Features', value: prod ? prod.features : '', controlName: 'features' },
      { type: 'info', title: 'Microsoft Dynamics', value: prod ? prod.microsoftDynamicsId : '', controlName: '' },
    ];
    this.fileSettings = [
      { type: 'catFiles', title: 'Files', value: this.files, valueItems: this.fileCategories, controlName: 'catFiles' },
    ]
  }

  setAddForm(prod?: Product) {
    this.formSettings = [
      { type: 'input', title: 'Name', value: prod ? prod.name : '', placeholder: 'Product Name...', controlName: 'name', validators: [Validators.required] },
      { type: 'textarea', title: 'Description', value: prod ? prod.description : '', placeholder: 'Description Name...', controlName: 'description', validators: [Validators.required, Validators.maxLength(255)] },
      { type: 'input', title: 'Vanity Url', value: prod ? prod.vanityUrl : '', placeholder: 'Vanity Url...', controlName: 'vanityUrl', validators: [Validators.required, Validators.maxLength(255)] },
      { type: 'input', title: 'External Id', value: prod ? prod.externalId : '', placeholder: 'External Id...', controlName: 'externalId', validators:[Validators.maxLength(100)] },
    ];
  }

  handleSave(event: Product) {
    let prod: Product = {
      id: this.product.id,
      name: event.name,
      description: event.description,
      vanityUrl: event.vanityUrl,
      published: event.published,
      features: event.features,
      microsoftDynamicsId: this.product.microsoftDynamicsId,
      pageUrl: event.pageUrl,
      externalId: event.externalId
    };
    this.editMode ?
      this.productService.editProduct(prod).subscribe(d => { this._service.success('Success!', 'Product edited!'); this.handleSaveEntities(event, d); this.router.navigate(['', 'products', 'edit', d.id]); }, err => { this._service.error('Error!', 'Something went wrong!') }) :
      this.productService.addProduct(prod).subscribe(d => { this._service.success('Success!', 'Product created!'); this.router.navigate(['', 'products', 'edit', d.id]) }, err => { this._service.error('Error!', 'Something went wrong!') })
  }

  handleSaveEntities(event: any, product: Product) {
    const productToCheck = new Product(
      product.name,
      product.id,
      product.description,
      product.vanityUrl,
      product.entityId,
      product.microsoftDynamicsId,
      product.features,
      product.pageUrl,
      product.published,
      product.externalId
    ); // needs creation with cunstructor to correctly check instanceof in handleChangeEntity

    //photos
    this.hs.handleChangeEntity(
      event.photo as Photo[],
      this.photos,
      productToCheck,
      (b: any) => this.productService.editPhoto(b),
      (b: any) => this.productService.deletePhoto(b)
    );
    //categories
    this.hs.handleChangeEntity(
      event.prodCategories as Category[],
      this.productCategories,
      productToCheck,
      (b: any) => this.productService.addProductCategory(b),
      (b: any) => this.productService.deleteProductCategory(b)
    );
    //keystats    
    this.hs.handleChangeEntity(
      event.keystats as Keystat[],
      this.keystats,
      productToCheck,
      (b: any) => this.productService.addKeyStat(b),
      (b: any) => this.productService.deleteKeyStat(b)
    );
    //app Types
    this.hs.handleChangeEntity(
      event.prodAppTypes as AppType[],
      this.productAppTypes,
      productToCheck,
      (b: any) => this.productService.addAppType(b),
      (b: any) => this.productService.deleteAppType(b)
    );
    //certifications
    this.hs.handleChangeEntity(
      event.prodCertifications as Certification[],
      this.productCertifications,
      productToCheck,
      (b: any) => this.productService.addProductCertification(b),
      (b: any) => this.productService.deleteProductCertification(b)
    );
    //complaints
    this.hs.handleChangeEntity(
      event.prodComplaints as Complaint[],
      this.productComplaints,
      productToCheck,
      (b: any) => this.productService.addProductComplaint(b),
      (b: any) => this.productService.deleteProductComplaint(b)
    );
  }

  formatCategoriesFormTree(parentCategory: FormTreeItem, sourceCategories: Category[]): void {
    let subCategory = sourceCategories.find((category) => category.parentId === parentCategory.id)
    if (subCategory)
      parentCategory.subItem = { id: subCategory.id, parentId: subCategory.parentId, name: subCategory.name }
    if (parentCategory.subItem) {
      this.formatCategoriesFormTree(parentCategory.subItem, sourceCategories);
    }
    else
      parentCategory.subItem = new FormTreeItem();
  }

  formatComplaintsFormTree(parentComplaint: FormTreeItem, sourceComplaints: Complaint[]): void {
    let subComplaint = sourceComplaints.find((complaint) => complaint.parentId === parentComplaint.id)
    if (subComplaint)
      parentComplaint.subItem = { id: subComplaint.id, parentId: subComplaint.parentId, name: subComplaint.name }
    if (parentComplaint.subItem) {
      this.formatCategoriesFormTree(parentComplaint.subItem, sourceComplaints);
    }
    else
      parentComplaint.subItem = new FormTreeItem();
  }
}

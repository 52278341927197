import { Component, OnInit, Input } from '@angular/core';
import { AbstractControl, Validators } from '@angular/forms';

@Component({
  selector: 'app-form-error',
  templateUrl: './form-error.component.html',
  styleUrls: ['./form-error.component.css']
})
export class FormErrorComponent implements OnInit {

  @Input() control?: AbstractControl;

  errorsList = [
    {id: 1, text: 'Field is Required!', type: 'required'},
    {id: 2, text: `Max Length is exceeded!`, type: 'maxlength'},
    {id: 3, text: `Url is Invalid!`, type: 'urlinvalid'},
  ]

  constructor() { }

  ngOnInit(): void {
  }

  getErrors() {
    let errors: string[] = [];
    if(this.control && this.control.touched && this.control.errors) {
      let cErrs = Object.keys(this.control.errors);
      cErrs.forEach(key => {
        let exist = this.errorsList.find(el => el.type == key);
        exist && errors.push(exist.text);
      })
    }
    return errors;
  }

}

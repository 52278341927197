export class SubmittalPackage {
    constructor(
        name: string = '',
        id: string = '',
        description: string = '',
        published: boolean = true,
    ) {
        this.name = name;
        this.id = id;
        this.description = description;
        this.published = published;
    }

    id: string;
    description: string;
    name: string;
    published?: boolean;
}

export class SubmittalProduct {
    submittalPackageId: string = '';
    productId: string = '';
    id?: string;
}

export class SubmittalProductCategory {
    productCategoryId: string = '';
    submittalPackageId: string = '';
    id?: string;
}
import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl, FormGroup } from '@angular/forms';
import { HelperService } from 'src/app/helpers/helper.service';
import { FormSettings } from 'src/app/models/formSettings.model';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';

@Component({
  selector: 'app-form-styled-textarea',
  templateUrl: './form-styled-textarea.component.html',
  styleUrls: ['./form-styled-textarea.component.css']
})
export class FormStyledTextareaComponent implements OnInit {

  @Input('settings') fs: FormSettings = new FormSettings();
  @Input('parentForm') pForm: AbstractControl = new FormGroup({});
  control?: AbstractControl;

  editor = ClassicEditor;
  editorConfig = {
    toolbar: [
      'Bold',
      'Italic',
      'NumberedList',
      'BulletedList',
      '-',
      'Outdent',
      'Indent',
      '-',
      'Link',
      'Unlink']
  }

  constructor(public hs: HelperService) {
    this.control = this.pForm.get(this.fs.controlName) || undefined;
  }

  ngOnInit(): void {
  }

  getFormGroup() {
    return this.pForm as FormGroup;
  }

}

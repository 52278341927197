import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, Validators, FormArray, Form, AbstractControl } from '@angular/forms';
import { FormSettings } from '../../models/formSettings.model';
import { ProductFile } from '../../models/file.model';
import { Keystat } from '../../models/keystat.model';
import { NotificationsService } from 'angular2-notifications';
import * as _ from 'lodash';
import { FormTreeItem } from 'src/app/models/formTreeItem';

@Component({
  selector: 'app-custom-form',
  templateUrl: './custom-form.component.html',
  styleUrls: ['./custom-form.component.css']
})
export class CustomFormComponent implements OnInit {

  @Input() formSettings: FormSettings[] = [];
  @Input() hideSave: boolean = false;
  @Output() submitted = new EventEmitter();

  constructor(private fb: FormBuilder, private _service: NotificationsService) { }

  mainForm?: FormGroup;

  ngOnInit(): void {
    this.formSettings.forEach(s => {
      if (s.value && s.valueItems && s.type == 'select') {
        s.value = s.valueItems.find(vi => vi.id == s.value);
      }
    });
    this.setForm();
  }

  setForm() {
    let controls: any = {};
    this.formSettings.forEach(s => {
      if (s.type == 'catFiles') {
        let files = s.value as ProductFile[];
        controls[s.controlName] = this.fb.array(files.map(f => {
          let syncUrlValue: any = [f.syncUrl];
          f.syncUrl && syncUrlValue.push(Validators.required);
          let fileCat;
          if (f.fileCategoryId && s.valueItems) {
            fileCat = s.valueItems.find(vi => vi.id == f.fileCategoryId);
          }
          return this.fb.group({
            id: [f.id],
            title: [f.title],
            fileName: [f.fileName],
            fileCategory: [fileCat, Validators.required],
            syncUrl: syncUrlValue,
            url: [f.url],
            published: [f.published],
            syncUrlMode: !!f.syncUrl,
            microsoftDynamicsId: f.microsoftDynamicsId
          });
        }))
      } else if (s.type === 'keystats') {
        let stats = s.value as Keystat[];
        controls[s.controlName] = this.fb.array(stats.map(f => {
          return this.fb.group({
            id: [f.id],
            name: [f.name, Validators.required],
            description: [f.description, Validators.required],
          });
        }))
      } else {
        let value = [_.cloneDeep(s.value)];
        if (s.validators?.length) value.push(s.validators);
        controls[s.controlName] = value;
      }
    });
    this.mainForm = this.fb.group(controls);
  }

  handleSubmit(event: any) {
    if (event) event.preventDefault();
    if (this.mainForm?.invalid) {
      this.formSettings.forEach(s => {
        this.mainForm?.get(s.controlName)?.markAllAsTouched();
      })
      this._service.warn('Warning!', 'Please fill to all required fields');
    } else {
      let resp: any = {};
      this.formSettings.forEach(fs => {
        if (fs.type === 'select') {
          resp[fs.controlName] = this.mainForm?.value[fs.controlName]?.id;
        } else if (fs.type === 'keystats') {
          resp[fs.controlName] = (this.mainForm?.controls[fs.controlName] as FormArray)?.controls.map(c => (c as FormGroup)?.value);
        } else if (fs.type === 'selectTree') {
          let resultControls: AbstractControl[] = []
          this.getControlsFromTreeItems(fs.value, resultControls);
          resp[fs.controlName] = resultControls.map(c => (c as FormGroup)?.value);
        } else {
          resp[fs.controlName] = _.cloneDeep(this.mainForm?.value[fs.controlName]);
        }
      })
      this.submitted.emit(resp);
    }
  }

  getControlsFromTreeItems(items: FormTreeItem[], result: AbstractControl[] = []) {
    items.forEach((parentItem) => {
      if (parentItem.id)
        result.push(this.fb.group({
          id: [parentItem.id],
          name: [parentItem.name, Validators.required]
        }));
      if (parentItem.subItem)
        this.getControlsFromTreeItems([parentItem.subItem], result);
    })
  }
}

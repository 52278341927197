import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Validators } from '@angular/forms';
import { FormSettings } from '../../../models/formSettings.model';
import { Category } from '../../../models/category.model';
import { CategoryService } from '../../../api/category.service';
import { NotificationsService } from 'angular2-notifications';

@Component({
  selector: 'app-categories-edit',
  templateUrl: './categories-edit.component.html',
  styleUrls: ['./categories-edit.component.css']
})
export class CategoriesEditComponent implements OnInit {

  editMode = '';
  formSettings: FormSettings[] = [];
  category = new Category();
  allCategories: Category[] = [];

  constructor(private activatedRoute: ActivatedRoute, private categoryService: CategoryService, private _service: NotificationsService, private router: Router) { }

  ngOnInit(): void {
    this.editMode = this.activatedRoute.snapshot.params['id'];
    if(this.editMode) {
      this.categoryService.getCategory(this.editMode).subscribe(cat => {
        this.category = cat;
        this.setForm(this.category);
      })
    } else {
      this.setForm();
    }
  }

  setForm(category?: Category) {
    this.categoryService.getCategories().subscribe(categories => {
        this.allCategories = categories.data;
        this.formSettings = [
          {type: 'input', title: 'Name', value: category ? category.name : '', placeholder: 'Category Name...', controlName: 'name', validators: [Validators.required]},
          {type: 'select', title: 'Parent Category', value: category ? category.parentId : '', valueItems: this.allCategories, controlName: 'parentId'}
        ];
      })
  }

  handleSave(event: Category) {
    let cat: Category = {
      id: this.category.id,
      name: event.name,
      parentId: event.parentId
    }
    this.editMode ? 
    this.categoryService.editCategory(cat).subscribe(d => { this._service.success('Success!', 'Category edited!'); this.router.navigate(['', 'categories']) }, err => { this._service.error('Error!', 'Something went wrong!') }) :
    this.categoryService.addCategory(cat).subscribe(d => { this._service.success('Success!', 'Category created!'); this.router.navigate(['', 'categories']) }, err => { this._service.error('Error!', 'Something went wrong!') })
  }

}

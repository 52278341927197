<label [for]="fs.controlName"
	[formGroup]="getFormGroup()">
	<div *ngIf="fs?.title" [ngClass]="{'err-input': hs.checkTouched(control)}">
		{{fs.title}}{{hs.getReq(control) ? '*' : ''}}:
	</div>
	<ckeditor [editor]="editor"
		tagName="textarea"
		[config]="editorConfig"
		[id]="fs.controlName"
		[formControlName]="fs.controlName">
	</ckeditor>

</label>
<app-form-error [control]="control"></app-form-error>

import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FormGroup, FormArray, Validators, AbstractControl, FormBuilder } from '@angular/forms';
import { FormSettings } from '../../../models/formSettings.model';
import { HelperService } from '../../../helpers/helper.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DeleteModalComponent } from '../../delete-modal/delete-modal.component';
import { ProductService } from '../../../api/product.service';
import { NotificationsService } from 'angular2-notifications';
import { FileUploader } from 'ng2-file-upload';
import { environment } from '../../../../environments/environment';
import { ProductFile } from '../../../models/file.model';
import { Subject, from, Subscription } from 'rxjs';
import { mergeAll, map, debounceTime, skip } from 'rxjs/operators';
import { CookieService } from 'ngx-cookie-service';
import * as _ from 'lodash';

@Component({
  selector: 'app-form-files',
  templateUrl: './form-files.component.html',
  styleUrls: ['./form-files.component.css']
})
export class FormFilesComponent implements OnInit {

  public uploader: FileUploader = new FileUploader({ url: environment.apiUrl + '/api/File/Upload', authToken: `Bearer ${this.cookieService.get('cmsAuthToken')}`, itemAlias: 'file' });

  @Input('settings') fs: FormSettings = new FormSettings();
  @Input('parentForm') pForm: FormGroup = new FormGroup({});
  control?: FormArray;
  hasBaseDropZoneOver: any;
  changesSubj = new Subject();
  sub?: Subscription;
  subForFile?: Subscription;

  constructor(
    public hs: HelperService,
    private modalService: NgbModal,
    private productService: ProductService,
    private _service: NotificationsService,
    private fb: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private cookieService: CookieService
  ) { }

  ngOnInit(): void {
    this.control = this.pForm.get(this.fs.controlName) as FormArray;
    this.control?.markAllAsTouched();
    this.subToChanges();
  }

  subToChanges() {
    if (this.sub) this.sub.unsubscribe();
    this.sub = from(this.control?.controls as FormGroup[]).pipe(map(fg => fg.valueChanges.pipe(debounceTime(1000), map(pf => ({pf, fg})))), mergeAll()).subscribe((data: {pf: ProductFile, fg: FormGroup}) => {
      let fileExistInProduct = (this.fs.value as ProductFile[]).find(pf => pf.id === data.pf.id);

      if(data.fg.valid && !fileExistInProduct) {
        data.pf.syncUrl ? this.setSyncUrlFile(data) : this.editFile(data);
      } else if(data.fg.valid && fileExistInProduct) {
        data.pf.syncUrl !== fileExistInProduct.syncUrl ? this.setSyncUrlFile(data, fileExistInProduct) : this.editFile(data);
      }
    });
  }

  editFile(data: {pf: ProductFile, fg: FormGroup}) {
    let file = _.cloneDeep(data.pf);
    file.fileCategoryId = (file as any)['fileCategory']['id'];
    let submittal = this.activatedRoute.snapshot.data.submittal;
    let id = this.activatedRoute.snapshot.params['id'];
    submittal ? file.submittalPackageId = id : file.productId = id;
    id && this.productService.editFile(file).subscribe(d => {
      data.fg.get('id')?.value.id == d.id;
      this.fs.value = _.uniqBy([...this.fs.value, d], 'id');
    }, err => this._service.error('Error!', 'File is not saved!'))
  }

  setSyncUrlFile(data: {pf: ProductFile, fg: FormGroup}, oldFile?: ProductFile) {
    if(data.pf.syncUrl) {
      this.productService.addFileByUrl(data.pf.syncUrl.trim()).subscribe((file: ProductFile) => {
        oldFile && this.handleDeleteFile('', data.pf.id, true);
        let pf = oldFile ? _.cloneDeep(data.pf) : data.pf; 
        pf.id = file.id;
        pf.url = file.url;
        pf.fileName = file.fileName;
        pf.published = file.published;
        oldFile ? data.fg = this.handleAddFile(true, pf) : '';
        this.editFile({pf: pf, fg: data.fg});
      }, err => data.fg.setErrors({urlinvalid: true}))
    }
  }

  getSettings(type: string) {
    let sett = new FormSettings();
    sett.controlName = type;
    sett.value = this.control?.value?.[type];
    sett.valueItems = this.fs.valueItems;
    if (type === 'fileCategory') sett.placeholder = 'File Category...'
    return sett;
  }

  handleDeleteFile(name: string = '', id: string, skipCheck: boolean = false) {
    if(skipCheck) {
      id && this.productService.deleteProductFile(id).subscribe(d => {
        let index = this.control?.controls.findIndex(c => c.value.id === id);
        index && index >= 0 && this.control?.removeAt(index);
      })
      return;
    }
    let modalRef = this.modalService.open(DeleteModalComponent);
    modalRef.componentInstance.name = name;
    modalRef.closed.subscribe(r => {
      if (r === 'OK') id && this.productService.deleteProductFile(id).subscribe(d => {
        this._service.success('Success!', `${name || ''} file is deleted!`);
        let index = this.control?.controls.findIndex(c => c.value.id === id);
        Number.isInteger(index) && (index as number) >= 0 && this.control?.removeAt((index as number));
      })
    })
  }

  handleAddFile(byUrl: boolean = false, file: ProductFile = new ProductFile()): FormGroup {
    let syncUrlValue = byUrl ? [file.syncUrl || 'Set Url Here...', Validators.required] : [''];
    let newFg = this.fb.group({
      title: [file.title],
      fileName: [file.fileName],
      published: [file.published],
      fileCategory: [(file as any)?.fileCategory || null, Validators.required],
      syncUrl: syncUrlValue,
      url: [file.url],
      id: [file.id],
      syncUrlMode: byUrl
    });
    this.control?.controls.push(newFg);
    this.control?.markAllAsTouched();
    this.subToChanges();
    return newFg;
  }

  changedFile(event: File[]) {
    this.hs.uploadQueue(this.uploader, (data: ProductFile) => {
      this.handleAddFile(false, data as ProductFile);
    })
  }

  public fileOverBase(e: any): void {
    this.hasBaseDropZoneOver = e;
  }

}

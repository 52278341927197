import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NotificationsService } from 'angular2-notifications';
import { CertificationService } from 'src/app/api/certification.service';
import { CustomTable } from 'src/app/entities/custom-table/custom-table.component';
import { DeleteModalComponent } from 'src/app/entities/delete-modal/delete-modal.component';
import { Certification } from 'src/app/models/certification.model';

@Component({
  selector: 'app-certifications',
  templateUrl: './certifications.component.html',
  styleUrls: ['./certifications.component.css']
})
export class CertificationsComponent implements OnInit {

  appTable?: CustomTable
  totalCount = 0;
  page = 1;
  count = 40;
  search = '';

  constructor(
    private certificationService: CertificationService,
    public router: Router,
    private _service: NotificationsService,
    private modalService: NgbModal
  ) { }

  ngOnInit(): void {
    this.refreshCertifications();
  }

  refreshCertifications() {
    this.certificationService.getCertifications(this.search, (this.page - 1) * this.count, this.count).subscribe(certifications => {
      this.totalCount = certifications.totalCount;
      this.appTable = {
        thead: ['Name', 'Id', 'Actions'],
        tbody: certifications.data.map(i => {
          return [
            i.name, i.id,
            [
              {
                label: 'Delete',
                class: 'btn btn-sm btn-danger m-r-md',
                callback: () => { this.handleDelete(i); }
              },
              {
                label: 'Edit',
                class: 'btn btn-sm btn-primary',
                callback: () => { this.handleEdit(i); }
              }
            ]
          ];
        })
      };
    })
  }

  handleDelete(certification: Certification) {
    let modalRef = this.modalService.open(DeleteModalComponent);
    modalRef.componentInstance.name = certification.name;
    modalRef.closed.subscribe(r => {
      if (r === 'OK') certification.id && this.certificationService.deleteCertification(certification.id).subscribe(d => {
        this._service.success('Success!', `${certification.name} substrate type is deleted!`);
        this.refreshCertifications();
      })
    })
  }

  handleEdit(certification: Certification) {
    this.router.navigate(['', 'certifications', 'edit', certification.id]);
  }

  pageChange(event: number) {
    this.page = event;
    this.refreshCertifications();
  }

  handleSearch(event: any) {
    this.search = event.value;
    this.page = 1;
    this.refreshCertifications();
  }
}

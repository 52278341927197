import { Validators } from '@angular/forms';

export class FormSettings {
    title?: string;
    type: string = 'input';
    placeholder?: string = 'Input...';
    value?: any;
    valueItems?: any[] = [];
    change?: Function;
    controlName: string = '';
    validators?: Validators[]
  }
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { environment } from '../../environments/environment';
import { AnalyticsUser } from '../models/analytics-user.model';
import { PaginationList } from '../models/pagination-list.model';

@Injectable({
  providedIn: 'root',
})
export class AnalyticsUserService {
  constructor(private http: HttpClient) {}

  getUsers(q = '', skip = 0, take = 40) {
    return this.http.get<PaginationList<AnalyticsUser>>(
      `${environment.apiUrl}/api/AnalyticsUser/AdminList?skip=${skip}&take=${take}&query=${q}`
    );
  }

  addUser(body: { email: string }) {
    return this.http.post<AnalyticsUser>(
      `${environment.apiUrl}/api/AnalyticsUser/Add`,
      body
    );
  }

  editUser(body: AnalyticsUser) {
    return this.http.put<AnalyticsUser>(
      `${environment.apiUrl}/api/AnalyticsUser/Edit`,
      body
    );
  }

  deleteUser(id: string) {
    return this.http.delete<void>(
      `${environment.apiUrl}/api/AnalyticsUser/Delete?id=${id}`
    );
  }

  getUser(id: string) {
    return this.http.get<AnalyticsUser>(
      `${environment.apiUrl}/api/AnalyticsUser/Get?id=${id}`
    );
  }
}

import { Component } from '@angular/core';
import { Options } from 'angular2-notifications';
import { AuthService } from './api/auth.service';
import { User } from './models/user.model';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {

  options: Options = {
    position: ['bottom', 'right'],
    timeOut: 5000
  };

  currUser?: User;

  pages = [
    {id: 1, name: 'Products', url: ['', 'products']},
    {id: 2, name: 'Categories', url: ['', 'categories']},
    {id: 3, name: 'Certifications', url: ['', 'certifications']},
    {id: 4, name: 'File Categories', url: ['', 'file-categories']},
    {id: 5, name: 'Submittal Packages', url: ['', 'submittal-packages']},
    {id: 6, name: 'Substrate Type', url: ['', 'app-type']},
    {id: 7, name: 'Search Settings', url: ['', 'search-settings']},
    {id: 8, name: 'Complaints', url: ['', 'complaints']},
    {id: 9, name: 'Analytics Users', url: ['', 'analytics-users']}
  ]

  activePage = 1;

  constructor(private authService: AuthService) {}

  ngOnInit() {
    let exist = this.pages.find(p => location.href.includes(p.url?.[1]));
    this.activePage = exist?.id || 1;
    this.authService.user.subscribe(u => {
      this.currUser = u;
    })
  }

}

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Country, Language } from '../models/cbo.model'

@Injectable({
  providedIn: 'root'
})
export class CBOService {

  constructor(private http: HttpClient) { }

  getCountries() {
    return this.http.get<Country[]>(`${environment.cboUrl}/api/Country/List`);
  }

  getLanguages() {
    return this.http.get<Language[]>(`${environment.cboUrl}/api/Language/List`);
  }

}

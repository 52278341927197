import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, AbstractControl, Validators } from '@angular/forms';
import { FormSettings } from '../../../models/formSettings.model';
import { HelperService } from '../../../helpers/helper.service';

@Component({
  selector: 'app-form-text',
  templateUrl: './form-text.component.html',
  styleUrls: ['./form-text.component.css']
})
export class FormTextComponent implements OnInit {

  @Input('settings') fs: FormSettings = new FormSettings();
  @Input('parentForm') pForm: AbstractControl = new FormGroup({});
  control?: AbstractControl;

  constructor(public hs: HelperService) { }

  ngOnInit(): void {
    this.control = this.pForm.get(this.fs.controlName) || undefined;
  }

  getFormGroup() {
    return this.pForm as FormGroup;
  }

}

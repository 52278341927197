export class Certification {
    constructor(name: string = '', id?: string, entityId: string = '') {
        this.name = name;
        this.id = id;
        this.entityId = entityId
    }

    name: string;
    id?: string;
    entityId?: string;
}

export class ProductCertification {
    certificationId: string = '';
    productId: string = '';
    id?: string;
}
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { AppType } from '../models/appType.model';

@Injectable({
  providedIn: 'root'
})
export class ApplicationService {

  constructor(private http: HttpClient) { }

  getAppType(id: string) {
    return this.http.get<AppType>(`${environment.apiUrl}/api/ApplicationType/Get?id=${id}`);
  }

  getAppTypes(q = '', skip = 0, take = 40) {
    return this.http.get<{data: AppType[], totalCount: number}>(`${environment.apiUrl}/api/ApplicationType/List?skip=${skip}&take=${take}&query=${q}`);
  }

  addAppType(body: AppType) {
    return this.http.post<AppType>(`${environment.apiUrl}/api/ApplicationType/Add`, body);
  }

  editAppType(body: AppType) {
    return this.http.put<AppType>(`${environment.apiUrl}/api/ApplicationType/Edit`, body);
  }

  deleteAppType(id: string) {
    return this.http.delete<AppType>(`${environment.apiUrl}/api/ApplicationType/Delete?id=${id}`);
  }

}

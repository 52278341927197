import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, FormArray, Validators, AbstractControl, FormBuilder } from '@angular/forms';
import { Subscription, from } from 'rxjs';
import { map, debounceTime, mergeAll } from 'rxjs/operators';
import { FormSettings } from '../../../models/formSettings.model';

@Component({
  selector: 'app-form-keystats',
  templateUrl: './form-keystats.component.html',
  styleUrls: ['./form-keystats.component.css']
})
export class FormKeystatsComponent implements OnInit {

  @Input('settings') fs: FormSettings = new FormSettings();
  @Input('parentForm') pForm: FormGroup = new FormGroup({});
  control?: FormArray;
  sub?: Subscription;

  constructor(
    private fb: FormBuilder
  ) { }

  ngOnInit(): void {
    this.control = this.pForm.get(this.fs.controlName) as FormArray;
    this.control?.markAllAsTouched();
    this.subToChanges();
  }

  getSettings(type: string) {
    let sett = new FormSettings();
    sett.controlName = type;
    sett.value = this.control?.value?.[type];
    return sett;
  }

  handleAddKeystat() {
    let newFg = this.fb.group({
      id: [''],
      name: ['', Validators.required],
      description: ['', Validators.required]
    });
    this.control?.controls.push(newFg);
    this.control?.markAllAsTouched();
    this.subToChanges();
  }

  handleDelete(i: number) {
    this.control?.controls.splice(i, 1);
  }

  subToChanges() {
    if (this.sub) this.sub.unsubscribe();
    this.sub = from(this.control?.controls as FormGroup[]).pipe(map(fg => fg.valueChanges.pipe(debounceTime(1000), map(pf => ({pf, fg})))), mergeAll()).subscribe((data: {pf: any, fg: FormGroup}) => {
      data.fg.value.id = '';
    });
  }

}

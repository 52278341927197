import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { isArray } from 'lodash';
import { from, Subscription } from 'rxjs';
import { debounceTime, map, mergeAll } from 'rxjs/operators';
import { HelperService } from 'src/app/helpers/helper.service';
import { FormSettings } from 'src/app/models/formSettings.model';
import { FormTreeItem } from 'src/app/models/formTreeItem';

@Component({
  selector: 'app-form-tree-select',
  templateUrl: './form-tree-select.component.html',
  styleUrls: ['./form-tree-select.component.css']
})
export class FormTreeSelectComponent implements OnInit {

  @Input('settings') formSetting: FormSettings = new FormSettings();
  @Input('parentForm') pForm: AbstractControl = new FormGroup({});
  @Input() level: number = 0;
  @Input() selectedItem?: FormTreeItem;

  @Output() updateControl = new EventEmitter<FormArray>();

  control?: FormArray;
  sub?: Subscription;
  availableItems: FormTreeItem[] = [];

  constructor(
    public hs: HelperService
  ) { }

  ngOnInit(): void {
    this.control = this.pForm.get(this.formSetting.controlName) as FormArray;
    this.control?.markAllAsTouched();
    this.getAvailableItems(this.formSetting.value, this.formSetting.valueItems ?? []);
  }

  getFormGroup() {
    return this.pForm as FormGroup;
  }

  selectItem(i: number, subItem: FormTreeItem) {
    this.formSetting.value[i] = subItem;
    this.getAvailableItems(this.formSetting.value, this.formSetting.valueItems ?? []);
    this.validateControl();
  }

  handleAdd() {
    this.formSetting.value.push({ parentId: '' });
  }

  getAvailableItems(existedItems: FormTreeItem[], availableItems: FormTreeItem[]) {
    this.availableItems = availableItems;
    existedItems.forEach((existedItem: FormTreeItem) => {
      this.availableItems = this.availableItems.filter((i) => i.id !== existedItem.id) ?? [];
      if (existedItem.subItem)
        this.getAvailableItems([existedItem.subItem], this.availableItems);
    });
  }

  handleDelete(i: number) {
    this.formSetting.value.splice(i, 1);
    this.getAvailableItems(this.formSetting.value, this.formSetting.valueItems ?? []);
    this.validateControl();
  }

  validateControl() {
    if (this.formSetting.validators?.length)
      this.control?.setErrors(this.formSetting.value.some((v: FormTreeItem) => v.id?.length) ? null : { required: true })
  }
}

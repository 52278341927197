export class ProductFile {
    fileCategoryId: string = '';
    productId?: string = '';
    title?: string = '';
    fileName: string = '';
    id: string = '';
    url: string = '';
    published: boolean = false;
    syncUrl?: string = '';
    submittalPackageId?: string = '';
    microsoftDynamicsId?: string;
}

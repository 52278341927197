<div class="image-wrapper">
    <div *ngIf="fs?.title">{{fs?.title}}</div>
    <div class="image-items">
        <div class="image-item" *ngFor="let photo of photos" [uploader]="uploader" ng2FileDrop (onFileDrop)="handleDrop($event)">
            <img [src]="photo.originalUrl" />
            <i *ngIf="photos.length > 1" class="delete-photo" (click)="handleDeletePhoto(photo)">X</i>
        </div>
    </div>
    <input class="hidden" type="file" ng2FileSelect [uploader]="uploader" #refToUpload (change)="handleSelect($event)" />
    <button *ngIf="!control?.errors?.maxlength" type="button" class="btn btn-primary btn-sm m-b-lg" (click)="refToUpload.click()">Upload</button>
</div>
<app-form-error [control]="control"></app-form-error>